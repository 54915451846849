// @flow

import React from 'react'
import { Link } from 'gatsby'
import Container from './Container'
import Grid from './Grid'
import ProductDetailSectionTitle from './ProductDetailSectionTitle'
import HTMLText from './HTMLText'
import Appear from './Appear'

type Props = {
  title: string,
  text: string,
  linkTitle: string,
  linkHref: string,
  color?: string,
  bgcolor?: string,
}

const ProductDetailText = ({ title, text, linkTitle, linkHref, color, bgcolor }: Props) => {

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  const linkCss = "customSimpleLink text-primary mt-8"
  const hasLink = (
    typeof linkTitle === "string"
    && linkTitle !== ""
    && typeof linkHref === "string"
    && linkHref !== ""
  )

  function handleHref(link) {
    const { href, title } = link
    if (href.charAt(0) === "/") return (
      <Link to={href} className={linkCss}>
        {title}
      </Link>
    )
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={linkCss}
      >
        <span className="customLink">{title}</span>
      </a>
    )
  }

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
        <div className="col-span-4 md:col-span-8 md:col-start-2">
          {title !== "" && <ProductDetailSectionTitle color={color ? textColor() : null} title={title} clasName="mb-8"/>}
          <Appear>
            <div style={{ color: textColor() }}>
              <HTMLText text={text} className="markdown" />
            </div>
          </Appear>
          {hasLink && <Appear>{handleHref({ title: linkTitle, href: linkHref })}</Appear>}
        </div>
      </Grid>
    </Container>
  )
}

export default ProductDetailText
