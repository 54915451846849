
// @flow
import React from 'react'
import Appear from './Appear'
import HTMLText from './HTMLText'

type Props = {
  title: string,
  textUp: string,
  textDown: string,
  email: string,
  emailtitle: string,
}

const ContactText = ({ title, textUp, textDown, email, emailtitle }: Props) => {

  return <div className="col-span-4 row-auto row-start-1 md:-ml-6 md:col-start-7 md:col-span-6">
    <Appear>
    <div className="pt-8 pb-24 pl-8 pr-4 bg-white md:px-10 md:py-12">
      <h2 className="mb-6 leading-tight text-bigger4 md:text-big3 font-secondary">{title}</h2>
      <HTMLText className="leading-relaxed font-primary text-small1 md:text-normal" text={textUp} />
        <a href={email} className="block text-primary text-small1 md:text-normal" >
          <span className="customLink">{emailtitle}</span>
        </a>
      <HTMLText className="mt-6 md:mb-2 font-primary text-small1 md:text-normal" text={textDown} />
    </div>
    </Appear>
  </div>
}

export default ContactText
