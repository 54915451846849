// @flow

import React from 'react'
import Container from './Container'
import SectionTitle from './SectionTitle'
import Appear from './Appear'
import useIsMobile from '../hooks/useIsMobile'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'
import { Link } from 'gatsby'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  supertitle: string,
  title: string,
  text: string,
  ctaHref: string,
  image: Object,
  file?: Object,
}

const HomeAbout = ({ supertitle, title, text, image, file, ctaHref }: Props) => {
  const isMobile = useIsMobile()

  const hasFile = typeof file !== "undefined" && file.file && file.file !== ""

  return <Container className="py-20 md:pt-24 md:pb-32">
    <div className="grid grid-cols-4 gap-8 md:grid-cols-12">
      {isMobile
        && <div className="col-span-3 col-start-2 row-start-1 row-end-2 md:col-span-6">
          <div className="mb-6">
            <SectionTitle title={supertitle}/>
          </div>
        </div>
      }
      <div className="relative col-span-3 col-start-2 row-start-2 row-end-3 mb-6 md:row-start-1 md:row-end-4 md:col-start-1 md:col-span-6 md:mb-0">
        {!isMobile
        && <div className="mb-6">
          <SectionTitle title={supertitle}/>
        </div>
        }
        <div className="md:col-start-1 md:col-span-3 md:row-start-2 md:row-end-3">
          <Link to={ctaHref} className="cursor-pointer">
            <Appear>
              <h2 className="mb-8 leading-tight text-bigger1 md:text-big3 font-secondary">{title}</h2>
            </Appear>
            <Appear>
              <HTMLText className="font-light text-small1 md:text-bigger1 font-primary" text={text} />
            </Appear>
          </Link>
        </div>
      </div>
      <div className="col-span-4 col-start-1 row-start-3 row-end-4 md:col-start-7 md:col-span-6 md:row-start-1 md:row-end-4">
        <Link to={ctaHref} className="cursor-pointer">
          <div className="relative">
            
              {hasFile ? (
                <video className="w-full h-auto pointer-events-none" loop muted autoPlay playsInline>
                  <source src={makeAssetUrl({ asset: file })} type="video/mp4" />
                </video>
              ) : (
                <ImageLazy image={image} alt={supertitle} className="w-full h-auto" />
              )}
          </div>
        </Link>
      </div>
    </div>
  </Container>
}

export default HomeAbout
