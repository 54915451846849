// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import ProductDetailSectionTitle from './ProductDetailSectionTitle'
import ProductDetailCard from './ProductDetailCard'

type ItemType = {
  id: string,
  image: Object,
  title: string,
  text: string,
  link1Title: string,
  link1Href: string,
  link2Title: string,
  link2Href: string,
}

type Props = {
  grid: boolean,
  title: string,
  items: Array<ItemType>,
  color?: string,
  bgcolor?: string,
}

const ProductDetailOptions = ({
  grid,
  title,
  items,
  color,
  bgcolor,
}: Props) => {

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }
  
  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
        <div className="col-span-4 md:col-span-10 md:col-start-2">
          <ProductDetailSectionTitle color={color ? textColor() : null} title={title}/>
          <ul className="grid grid-cols-2 col-span-4 gap-5 md:grid-cols-10 md:col-span-10 md:col-start-2 md:gap-6">
            {items.map(item => (
              <li
                key={item.id}
                className={grid ? "col-span-1 md:col-span-3" : "col-span-2 md:col-span-10"}
                style={{ color: textColor() }}
              >
                <ProductDetailCard
                  grid={grid}
                  {...item}
                />
              </li>
            ))}
          </ul>
        </div>
      </Grid>
    </Container>
  )
}

export default ProductDetailOptions
