// @flow

import React from 'react'
import Appear from './Appear'
import HTMLText from './HTMLText'
import Container from './Container'
import Grid from './Grid'
import AboutSlider from './AboutSlider'
import ProductDetailSectionTitle from './ProductDetailSectionTitle'

type Props = {
  title: string,
  text: string,
  sliderItems: Array<Object>,
  color?: string,
  bgcolor?: string,
}

const SliderText = ({
  title,
  text,
  sliderItems,
  color,
  bgcolor,
}: Props) => {

 const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
        <div className="col-span-4 row-start-2 md:col-span-5 md:col-start-2 md:row-start-1">
          <AboutSlider items={sliderItems} type="itemDetails" detailClassName="mt-0" customArrowsColor={textColor()}/>
        </div>
        <div className="col-span-4 row-start-1 md:pl-4 md:col-span-5 md:col-start-7" style={{ color: textColor() }}>
          <Appear>
          {title && <ProductDetailSectionTitle color={color ? textColor() : null} title={title}/>}
            {text && (
              <Appear>
                <HTMLText text={text} />
              </Appear>
            )}
          </Appear>
        </div>
      </Grid>
    </Container>
  )
}

export default SliderText
