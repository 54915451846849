// @flow

import React from 'react'
const ReactMarkdown = require('react-markdown/with-html')

type Props = {
  className?: string,
  text: string,
}

const HTMLText = ({ className = "", text }: Props) => {
  if (!text || typeof text !== 'string' || text.length === 0) {
    return ''
  }

  let parsedText = text

  parsedText = parsedText.replace(/[ ]•\s/g, " - ")
  parsedText = parsedText.replace(/[ ]·\s/g, " - ")


  return (
    <ReactMarkdown
      className={`${className} html-container markdown customList`}
      source={parsedText}
      escapeHtml={false}
    />
  )
}

export default HTMLText
