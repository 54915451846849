// @flow

import React from 'react'
import Appear from './Appear'
import Svg from './Svg'

type Props = {
  width?: string,
  height?: string
}

const ArrowDownBig = ({ width, height }: Props) => {
  return <div className={`w-${width ? width : "100px"} h-${height ? height : "100px"}`}>
    <Appear>
      <Svg name="arrow-down-big" width="100%" height="100%" color="primary" />
    </Appear>
  </div>
}

export default ArrowDownBig
