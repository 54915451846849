// @flow

import React, { useRef } from 'react'
import Container from './Container'
import Grid from './Grid'
import Svg from './Svg'
import Appear from './Appear'

import SwiperCore, { Navigation, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import makeAssetUrl from '../lib/makeAssetUrl'

SwiperCore.use([Navigation, A11y])

type ItemType = {
  id: string,
  image: Object,
  caption: string,
}

type Props = {
  title: string,
  items: Array<ItemType>,
  color?: string,
  bgcolor?: string,
  arrowsLabel?: string,
}

type ButtonType = {
  className?: string,
  direction: "left" | "right",
  color?: string,
}

const Button = ({ className = "", direction, color }: ButtonType) => {
  return (
    <button
      type="button"
      className={`transform transition-transform duration-500 ease-in-out hover:scale-110 focus:outline-none ${className}`}
      onClick={() => {}}
    >
      <Svg
        name={`arrow-${direction}`}
        width="50px"
        height="50px"
        color={color || "black"}
      />
    </button>
  )
}

const ProductDetailSlider = ({ title, items, color, bgcolor, arrowsLabel }: Props) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const swiperParams = {
    spaceBetween: 20,
    slidesPerView: 1,
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: "3.75",
        spaceBetween: 24,
      }
    }
  }

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  return (
    <Container backgroundColor={backgroundColor()} overflowHidden={true}>
      <Grid className="py-12">
        <div className="flex items-center justify-start col-span-4 mb-4 md:col-span-8 md:col-start-2 md:mb-0 md:row-start-1" style={{ color: textColor() }}>
          {title !== "" && (
            <Appear>
              <span className={`font-medium font-secondary text-bigger3 ${color ? '' : 'text-primary'}`}>
                {title}
              </span>
            </Appear>
          )}
        </div>
        <Appear className="flex items-center justify-end col-span-4 row-start-3 pt-2 md:col-span-3 md:row-start-1">
          <div style={{ color: textColor() }}>
            {arrowsLabel}
          </div>
          <div ref={prevRef}>
            <Button className="mx-5" direction="left" color={textColor()} />
          </div>
          <div ref={nextRef}>
            <Button direction="right" color={textColor()} />
          </div>
        </Appear>
        <div className="col-span-4 md:col-span-11 md:col-start-2" style={{ color: textColor() }}>
          <Swiper
            {...swiperParams}
            onInit={swiper => {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
              swiper.navigation.init()
              swiper.navigation.update()
            }}
          >
            {items.map(item => (
              <SwiperSlide key={item.id}>
                <Appear>
                  <div
                    className="bg-white bg-center bg-no-repeat bg-cover"
                    style={{
                      paddingBottom: "82.41469816%",
                      backgroundImage: `url(${makeAssetUrl({ asset: item.image, isImage: true })})`,
                    }}
                  />
                </Appear>
                <Appear>
                  {typeof item.caption === "string" && item.caption !== "" && <div className="hidden mt-5 md:block text-small1">{item.caption}</div>}
                </Appear>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Grid>
      </Container>
  )
}

export default ProductDetailSlider
