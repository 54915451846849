// @flow

import React from 'react'
import { Link } from 'gatsby'
import Appear from './Appear'
import HTMLText from './HTMLText'
import makeAssetUrl from '../lib/makeAssetUrl'
type Props = {
  grid: boolean,
  id: string,
  image: Object,
  title: string,
  text: string,
  link1Title: string,
  link1Href: string,
  link2Title: string,
  link2Href: string,
}

const ProductDetailCard = ({
  grid,
  image,
  title,
  text,
  link1Title,
  link1Href,
  link2Title,
  link2Href,
}: Props) => {
  const linkCss = "text-primary customSimpleLink mr-5"
  const hasLink1 = (
    typeof link1Title === "string"
    && link1Title !== ""
    && typeof link1Href === "string"
    && link1Href !== ""
  )

  const hasLink2 = (
    typeof link2Title === "string"
    && link2Title !== ""
    && typeof link2Href === "string"
    && link2Href !== ""
  )

  function handleHref(link) {
    const { href, title } = link
    if (href.charAt(0) === "/") return (
      <Link to={href} className={linkCss}>
        {title}
      </Link>
    )
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={linkCss}
      >
        <span className="customLink">{title}</span>
      </a>
    )
  }

  return (
    <div className={grid ? "mb-5 md:mb-0" : "grid grid-cols-1 md:grid-cols-10 gap-5 font-light md:gap-6"}>
      <div className={grid ? "" : "col-span-1 md:col-span-3"}>
        <Appear>
          <div
            className="bg-white bg-center bg-no-repeat bg-cover"
            style={{
              paddingBottom: "65.4676259%",
              backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`,
            }}
          />
        </Appear>
      </div>
      <div className={grid ? "" : "col-span-1 md:col-span-6 md:col-end-11 mb-5 md:mb-0"}>
        <Appear>
          <h6 className={`mb-5 md:mb-8 font-medium font-secondary text-bigger2 ${grid ? "mt-5" : "mt-1 md:mt-0"}`}>
            {title}
          </h6>
        </Appear>
        {text && (
          <Appear>
            <HTMLText className={`${hasLink1 || hasLink2 ? "mb-5" : ""}`} text={text} />
          </Appear>
        )}
        <div className="flex flex-wrap items-start justify-start">
          {hasLink1 && <Appear>{handleHref({ title: link1Title, href: link1Href })}</Appear>}
          {hasLink2 && <Appear>{handleHref({ title: link2Title, href: link2Href })}</Appear>}
        </div>
      </div>
    </div>
  )
}

export default ProductDetailCard
