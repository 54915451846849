// @flow

import React, { useEffect, useRef, useState } from 'react'
import type { Ref } from 'types/'
import Appear from './Appear'

type Props = {
  first?: boolean,
  title: string,
  url: string,
  mobile: boolean,
  marginBottom: number,
  onClickCallback: Function,
  currentSection: string
}

const AboutHeadingLink = ({ first, title, url, onClickCallback, mobile, marginBottom, currentSection }: Props) => {
  
  const [white, setWhite] = useState(false)
  const [active, setActive] = useState(false)
  const observer = useRef(null)
  const element: Ref = useRef({})

  useEffect(() => {
    if (typeof currentSection !== "undefined" && currentSection === url) {
      setActive(true)
    } else setActive(false)
  }, [currentSection])
  
  useEffect(() => {
    if (window.IntersectionObserver) {
      let thresholds = document.querySelectorAll("[data-name='dark-section']")
      Object.keys(thresholds).forEach(item => {
        const threshold = thresholds[item]
        observer.current = new IntersectionObserver(entries => {
          setWhite(entries[0].isIntersecting)
        }, { rootMargin: `-148px 0px -${marginBottom}px 0px` })
  
        threshold && observer.current.observe(threshold)
  
        // On unmount
        return () => {
          observer.current && observer.current.disconnect()
        }
      })
    }
  }, [mobile, marginBottom])

  function handleTextColor(){
    if (active) {
      return "text-primary"
    } else return white ? "md:text-white" : "text-black"
  }
  
  return <Appear>
    <div 
      ref={element}
      className={`py-4 font-primary text-small1 md:text-normal md:hover:text-primary md:border-b transition-colors duration-100 ease-linear cursor-pointer
      ${white === true ? `${handleTextColor()} md:border-white` : `${handleTextColor()} md:border-black`}
      ${first ? "md:pb-4 md:pt-0" : ""}
      `}
      onClick={() => onClickCallback(url)}
    >
      {title}
    </div>
  </Appear>
}

export default AboutHeadingLink
