// @flow

import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Svg from './Svg'

type Props = {
  backgroundColor: string,
  text?: string,
  onClick?: Function,
  isLink?: boolean,
  url?: string,
  isSubmit?: boolean,
  disabled?: boolean,
  mouseIsOver?: boolean,
}

const ArrowButtonOpen = ({ backgroundColor, text, onClick, isLink, url, isSubmit, disabled, mouseIsOver}: Props) => {
  const [mouseOver, setMouseOver] = useState()

  useEffect(() => {
    if (mouseIsOver) {
      setMouseOver(mouseIsOver)
    } else {
      setMouseOver(false)
    }
  }, [mouseIsOver])

  const content = 
  <div
  className="flex items-center"
  onMouseOver={() => mouseIsOver ? setMouseOver(mouseIsOver) : setMouseOver(true)}
  onMouseLeave={() => mouseIsOver ? setMouseOver(mouseIsOver) : setMouseOver(false)}
  >
    {/* <div className={`transition duration-500 ease-in-out transform ${mouseOver ? 'md:scale-110' : ''}`}>
      <div className={`bg-${backgroundColor} rounded-full w-55px lg:w-60px h-55px lg:h-60px flex items-center justify-center`}>
        <Svg name="arrow-top-right" width="22px" height="22px" color="white" />
      </div>
    </div> */}
    { text ? ( <span className={`font-primary font-light text-small1 md:text-normal customLink ${mouseOver ? 'customLinkHover' : 'customLink'} text-${backgroundColor}`}>{text}</span> ) : ""}
  </div>

if (isSubmit) {
  return (
    <button type="submit" value="Submit" className="focus:outline-none">
      {content}
    </button>
  )
}
  
if (isLink) {
  return (
    <Link to={url ? url : ''}>
      {content}
    </Link>
  )
}

if (disabled) {
  return (
    <div>
      {content}
    </div>
  )
}

return (
    <button onClick={onClick ? onClick : () => {}} className="focus:outline-none">
      {content}
    </button>
  )
}

export default ArrowButtonOpen
