// @flow

import React, { useEffect, useRef } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import Container from './Container'
import SectionTitle from './SectionTitle'
import Number from './Number'
import ArrowDownBig from './ArrowDownBig'

type Props = {
  title?: string,
  values: Array<Object>,
  darkMode?: boolean,
  menuid?: string,
}

const Numbers = ({ title, values, darkMode, menuid }: Props) => {
  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const isMobile = useIsMobile()
  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])

  return <div data-name="dark-section" data-menu-link={menuAnchor} ref={threshold}>
    <Container className={`${darkMode ? "pb-40" : "pb-20"}`} backgroundColor={darkMode ? "bg-dark" : "bg-transparent"}>
      <div className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6 lg:gap-10">
        <div className={`${darkMode ? "col-start-2 md:col-start-4 col-span-3 md:col-span-9" : "col-span-4 md:col-span-12"} mt-16 mb-10 md:mt-20 md:mb-12`}>
          {title && <SectionTitle title={title} textColor={darkMode ? "white" : ""}/>}
        </div>
        <div className={`${darkMode ? "col-start-2 md:col-start-4 col-span-3 md:col-span-9" : "col-start-2 md:col-start-1 col-span-3 md:col-span-11 lg:col-span-10"}`}>
          <div className="flex flex-col md:flex-row md:flex-wrap">
            {values.map(value => <div key={value.id} className="w-fill md:w-1/3">
                <Number value={value.value} title={value.title} text={value.text} textColor={darkMode ? "white" : "dark"} />
              </div>)}
          </div>
        </div>
        {!darkMode && <div className="items-center justify-end hidden col-start-4 row-start-3 row-end-4 md:col-end-13 md:col-span-2 md:flex">
          <ArrowDownBig />
        </div>}
      </div>
    </Container>
  </div>
}

export default Numbers
