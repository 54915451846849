// @flow

import React from 'react'
import Appear from './Appear'
import Grid from './Grid'

type Props = {
  square?: boolean,
  logo?: boolean,
}

const Separator = ({ square = true, logo = false }: Props) => {
  return (
    <Appear>
      <Grid className="my-12">
        <div className="flex items-center justify-between col-span-4 mx-4 md:mx-0 md:col-start-2 md:col-span-10">
          <div className="flex-1 w-full h-px bg-primary" />
          {square && <div className="w-2 h-2 mx-14 bg-primary" />}
          {logo && <span className="tracking-widest mx-11 font-secondary text-tiny">A 2 C</span>}
          <div className="flex-1 w-full h-px bg-primary" />
        </div>
      </Grid>
    </Appear>
  )
}

export default Separator
