// @flow

import React from 'react'
import { Link } from 'gatsby'
// import ImageLazy from './ImageLazy'
import makeAssetUrl from '../lib/makeAssetUrl'
type Props = {
  categories: Array<string>,
  title: string,
  href: string,
  link: string,
  image: Object
}

const ProductCard = ({
  categories,
  title,
  href,
  image,
}: Props) => {

  return (
    <Link className="flex flex-col items-stretch w-full h-full bg-white group" to={href}>
      <div className="relative" style={{ paddingBottom: "75%" }}>
        <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
          {image && image.normal !== "" && image.lazy !== "" && (
            <div className="absolute inset-0 transition transform duration-normal hover:scale-105" style={{
              backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }} />
            // <ImageLazy
            //   image={image}
            //   alt={title}
            //   className="w-full h-full transition-transform duration-500 ease-in-out transform group-hover:scale-110"
            //   ignoreLazy={false}
            //   threshold='50%'
            // />
          )}
        </div>
      </div>
      <div className="py-5 pl-5 pr-3 md:pt-5 md:pb-6 md:pl-5 md:pr-6">
        <span className="block mb-3 font-light leading-snug text-small2 md:text-small1">
          {typeof categories === 'string' ? categories : categories[0]}
        </span>
        <h6 className="leading-tight transition-colors ease-linear duration-250 font-secondary text-small md:text-bigger2 group-hover:text-primary">
          {title} 
        </h6>
      </div>
    </Link>
  )
}

export default ProductCard
