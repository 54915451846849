// @flow

import React from 'react'
import { Link } from 'gatsby'
import Container from './Container'
import Grid from './Grid'
import ProductDetailSectionTitle from './ProductDetailSectionTitle'
import HTMLText from './HTMLText'
import Appear from './Appear'
import ImageLazy from './ImageLazy'

type Props = {
  reverse: number,
  image: Object,
  title: string,
  text: string,
  linkTitle: string,
  linkHref: string,
  color?: string,
  bgcolor?: string,
}

const ProductDetailBlock = ({ reverse, image, title, text, linkTitle, linkHref, color, bgcolor }: Props) => {     

const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  const linkCss = "customSimpleLink text-primary mt-8"
  const hasLink = (
    typeof linkTitle === "string"
    && linkTitle !== ""
    && typeof linkHref === "string"
    && linkHref !== ""
  )

  function handleHref(link) {
    const { href, title } = link
    if (href.charAt(0) === "/") return (
      <Link to={href} className={linkCss}>
        {title}
      </Link>
    )
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={linkCss}
      >
        <span className="customLink">{title}</span>
      </a>
    )
  }

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
          <div className={`${reverse ? "col-span-3 md:col-span-8" : "col-span-4 md:col-span-8 md:col-start-2"}`} style={{ color: textColor() }}>
            {title !== "" && <ProductDetailSectionTitle color={color ? textColor() : null} title={title} clasName="mb-8" />}
            <Appear>
              <HTMLText text={text} className="markdown" />
            </Appear>
            {hasLink && <Appear>{handleHref({ title: linkTitle, href: linkHref })}</Appear>}
          </div>
          <div className={`mb-5 md:mb-0 ${reverse ? "md:col-start-2 row-start-1 col-span-3 md:col-span-3" : "row-start-2 md:row-start-auto col-span-2 md:col-span-3"}`}>
            <div className="flex items-center justify-center w-full overflow-hidden max-h-400px md:max-h-800px">
              <ImageLazy image={image} alt={title} className="w-full" />
            </div>
          </div>
      </Grid>
    </Container>
  )
}

export default ProductDetailBlock
