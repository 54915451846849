// @flow

import React, { useContext, useEffect, useState } from 'react'
import FiltersContext from '../store/FiltersContext'
import Appear from './Appear'
import ProductCard from './ProductCard'

type Props = {
  items: Array<Object>,
}

const ProductsCards = ({ items }: Props) => {
  const {
    filters: filtersCategories,
    activeFiltersIDs,
    openedDropdown
  } = useContext(FiltersContext)  
  const [filteredItems, setFilteredItems] = useState([])
  const [commonParentImage, setCommonParentImage] = useState(null)
  
  function findParentCategorySubIDs() {
    if (openedDropdown === "" || openedDropdown === "0") return []
    const value = filtersCategories.find(category => {
      if (category.id !== openedDropdown || !category.items) return false
      else return true
    })
    return value.items.map(subcategory => subcategory.id)
  }

  useEffect(() => {
    let newItems = [...items]
    const parentCategorySubIDs = findParentCategorySubIDs()

    const activeIDs = (
      activeFiltersIDs.length === 0
        ? [openedDropdown, ...parentCategorySubIDs]
        : activeFiltersIDs
    )

    if (openedDropdown !== "") {
      if (openedDropdown !== "0") {
        const result = newItems.filter(item => (
          item.category.some(category => activeIDs.includes(category))
        ))
        newItems = result
      } else {
        const result = newItems.filter(item => item.news !== 0)
        newItems = result
      }
    }
    setFilteredItems(newItems)
  }, [openedDropdown, activeFiltersIDs])

  useEffect(() => {
    if (openedDropdown !== "" && openedDropdown !== "0") {
      const commonParent = filtersCategories.find(filter => filter.id === openedDropdown)
      setCommonParentImage(commonParent.image)
    } else {
      setCommonParentImage(null)
    }
  }, [openedDropdown])

  return (
    <div className="grid grid-cols-4 gap-2 pt-8 pb-24 md:pt-0 md:col-start-4 md:col-end-13 md:grid-cols-9 md:gap-6">
      {filteredItems.map(item => (
        <div
          key={`${item.id}-${openedDropdown}-${Math.random()}`}
          className="col-span-2 md:col-span-3"
        >
          <Appear className="h-full">
            <ProductCard
              category={item.category}
              categories={item.categories}
              title={item.title}
              href={item.href}
              link={item.link}
              image={
                item.image
                ? item.image
                : commonParentImage
              }
            />
          </Appear>
        </div>
      ))}
    </div>
  )
}

export default ProductsCards
