// @flow

import React, { useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Link } from 'gatsby'
import Appear from './Appear'
import useIsMobile from 'hooks/useIsMobile'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  type: string,
  dateFrom: string,
  dateTo: string,
  title: string,
  place: string,
  href?: string,
  link?: string,
  url?: string,
  bgImage?: Object,
  showBackground?: string,
}

const FeaturedCard = ({ type, dateFrom, dateTo, title, place, href, link, url, bgImage, showBackground }: Props) => {
  const isMobile = useIsMobile()
  let borderColor = ""
  let typeTitle = ""
  switch(type) {
    case "host":
    borderColor = "border-filter-host"
    typeTitle = "Organizado por A2C"
    break
    case "collab":
    borderColor = "border-filter-collab"
    typeTitle = "Colabora A2C"
    break
    case "participate":
    borderColor = "border-filter-part"
    typeTitle= "A2C Participa"
    break
    default:
    borderColor = "border-filter-other"
    typeTitle= null
    break
  }

  const [hover, setHover] = useState(false)

  const parsedDateFrom = dayjs(dateFrom).locale("es").format("DD.MM.YYYY")
  const parsedDateTo = dayjs(dateTo).locale("es").format("DD.MM.YYYY")


  return <div className="flex flex-col justify-between flex-1">
  <Appear className="h-full">
    <Link
      to={href}
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)}
      className="h-full" 
    >
      <div
        className={`relative h-full text-white bg-event-gray border-l-10 ${borderColor} flex flex-col justify-between flex-1 py-8 pl-5 pr-3 md:p-8 md:pt-12`}
        style={{
          minHeight: isMobile ? 250 : 300,
          backgroundImage: bgImage && showBackground && showBackground === "1" ? `url(${makeAssetUrl({ asset: bgImage, isImage: true })})` : null,
          backgroundRepeat: bgImage && showBackground && showBackground === "1" ? "no-repeat" : null,
          backgroundPosition: bgImage && showBackground && showBackground === "1" ? "center center" : null,
          backgroundSize: bgImage && showBackground && showBackground === "1" ? "cover" : null
        }}
      >
        <div 
          className="absolute top-0 bottom-0 left-0 right-0"
          style={{
            backgroundImage: bgImage && showBackground && showBackground === "1" ? 'linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.8))' : ''
          }}
        />
        <div className="relative">
          <div className="w-4 h-4 rounded-sm bg-primary" />
          <div className="pl-6">
            <span className="font-light text-small1 md:text-normal">{parsedDateFrom} - {parsedDateTo}</span>
            <h3 className={`w-2/3 mt-3 mb-8 font-semibold leading-tight md:w-full font-secondary text-bigger3 md:text-bigger4 md:mt-4 ${hover ? "text-primary" : "text-white"}`}>{title}</h3>
          </div>
        </div>
        <div className="relative flex items-end pl-6">
          <div className="flex flex-col items-start justify-end font-light text-small1 md:text-normal">
            {typeTitle && <span>{typeTitle}</span>}
            <span>{place}</span>
            {url && <span className={`text-primary ${hover ? "customLinkHover" : "customLink"}`}>{link}</span>}
          </div>
        </div>
      </div>
    </Link>
  </Appear>  
  </div>
}

export default FeaturedCard
