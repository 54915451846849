// @flow

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Appear from './Appear'
import Container from './Container'
import Grid from './Grid'
import Svg from './Svg'

type ItemProps = {
  item: Object,
  callback?: (type: string) => void,
  selectedFilter?: string,
  active?: boolean,
}

type Props = {
  text: string,
  items: Array<Object>,
  callback: any,
}

const FilterItem = ({ item, callback, selectedFilter, active = false }: ItemProps) => {
  const TAG = typeof callback !== "undefined" ? "button" : "div"
  return (
    <TAG
      type={TAG === "button" ? "button" : ""}
      onClick={e => {
        if (typeof callback !== "undefined") {
          e.stopPropagation()
          callback(item.type)
        }
      }}
      className={`
        flex items-center justify-start md:justify-center md:p-0 font-light
        focus:outline-none w-full md:w-auto text-left text-small1 md:text-normal
        ${TAG === "button" ? "py-4" : ""}
        ${active ? "" : "hover:underline"}
        ${selectedFilter === item.type && !active ? "underline" : ""}
      `}
    >
      <div className={`flex-0 w-5 h-5 mr-5 md:mr-2 rounded-full bg-${item.color}`}/>
      {item.name}
    </TAG>
  )
}

const CalendarFilters = ({ text, items, callback }: Props) => {
  const [openedDropdown, setOpenedDropdown] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState()

  const handleFilterClick = (type) => {
    type === selectedFilter
      ? setSelectedFilter(undefined)
      : setSelectedFilter(type)
    setOpenedDropdown(false)
    callback(type)
  }

  const dropdownInitial = {
    height: 0,
  }

  const dropdownAnimate = {
    height: "auto",
  }

  const dropdownExit = {
    height: 0,
  }

  const transition = { type: "tween", duration: 0.250, easing: [0.4, 0, 0.2, 1] }

  return (
    <div className="relative md:mb-1">
      <div className="absolute inset-y-0 right-0 hidden w-1/2 bg-white md:block" />
      <Container className="relative">
        <Grid className="md:py-8 md:bg-white">
          <Appear className={`hidden md:block md:pl-12 md:col-span-${12 - items[0].length * 2}`}>
            <span>{text}</span>
          </Appear>
          <Appear className="col-span-4 md:hidden">
            <div
              className="relative p-4 border border-black md:hidden text-small1"
              onClick={() => setOpenedDropdown(value => !value)}
            >
              {selectedFilter ? (
                <div>
                  {items[0].map(item => {
                    if (item.type === selectedFilter) return (
                      <FilterItem key={item.id} item={item} active />
                    )
                  })}
                </div>
              ) : (
                <span>{text}</span>
              )}
              <div className="absolute inset-y-0 right-0 flex items-center justify-center p-4">
                <motion.span
                  initial={{ rotate: 0 }}
                  animate={openedDropdown ? "on" : "off"}
                  variants={{
                    off: { rotate: 0 },
                    on: { rotate: -180 },
                  }}
                  transition={transition}
                >
                  <Svg name="arrow-down" color="black" width="14" />
                </motion.span>
              </div>
            </div>
            <AnimatePresence initial={false}>
              {openedDropdown && (
                <motion.div
                  key="dropdown-overlay"
                  className="-mt-px overflow-hidden border-b border-l border-r border-black"
                  initial={dropdownInitial}
                  animate={dropdownAnimate}
                  exit={dropdownExit}
                  transition={transition}
                >
                  <motion.div
                    className="px-4 py-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.05 }}
                  >
                    {items[0].map(item => (
                      <FilterItem
                        key={item.id}
                        item={item}
                        callback={handleFilterClick}
                        selectedFilter={selectedFilter}
                      />
                    ))}
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </Appear>
          {items[0].map(item => (
            <Appear key={item.id} className="hidden col-span-2 md:block">
              <FilterItem
                item={item}
                callback={handleFilterClick}
                selectedFilter={selectedFilter}
              />
            </Appear>
          ))}
        </Grid>
      </Container>
    </div>
  )
}

export default CalendarFilters
