// @flow

import React from "react"
import { Link } from "gatsby"
import ArrowButtonOpen from "./ArrowButtonOpen"
import Appear from "./Appear"
import makeAssetUrl from '../lib/makeAssetUrl'
type Props = {
  title: string,
  href: string,
  src: Object,
  size: string,
}

const ProductCategoryCard = ({ title, href, src, size }: Props) => {

  return <Link to={href}>
    <Appear>
      <div
        className={`relative overflow-hidden group
          h-${ size === "small" ? "280px" : "280px"}
          md:h-${ size === "small" ? "300px" : "400px"}
          lg:h-${ size === "small" ? "400px" : "500px"}
        `}
      >
        <div
          className="absolute top-0 left-0 w-full h-full transition-transform duration-500 ease-in-out transform bg-top bg-no-repeat bg-cover md:group-hover:scale-110"
          style={{backgroundImage: `url(${makeAssetUrl({ asset: src, isImage: true })})`}}
        />
        <div className="absolute bottom-0 flex items-center w-full px-5 py-4 bg-dark md:py-6 lg:py-8">
          <h3 className="w-3/4 leading-tight text-white break-words md:pr-2 font-secondary text-small1 md:text-bigger2 md:w-4/5">{title}</h3>
          {/* <div className={`absolute top-0 right-0 ${size === "big" ? "mr-5" : "mr-10px"} -mt-8 md:mr-4 lg:mr-8 md:-mt-30px`}>
            <ArrowButtonOpen backgroundColor={"primary"} />
          </div> */}
        </div>
      </div>
    </Appear>
  </Link>
}

export default ProductCategoryCard
