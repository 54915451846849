/* eslint-disable react/jsx-key */
// @flow

import React from 'react'
import Appear from './Appear'
import HTMLText from './HTMLText'
import Container from './Container'
import Grid from './Grid'
import ImageLazy from './ImageLazy'
import ProductDetailSectionTitle from './ProductDetailSectionTitle'

type Column = {
  id: string,
  title?: string,
  text?: string,
  image?: Object,
}

type Props = {
  columns: Array<Column>,
  color?: string,
  bgcolor?: string,
  title?: string,
}

const TextMediaColumns = ({
  columns,
  color,
  bgcolor,
  title,
}: Props) => {

   const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  const colsNum = columns.length

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
        <div className="col-span-4 md:col-start-2 md:col-span-10">
          {title && <ProductDetailSectionTitle title={title}/>}
          <div className="grid grid-cols-4 gap-5 md:grid-cols-12 md:gap-6">
            {columns.map(column => {
              return (
                <div key={column.id} className={colsNum < 4 ? "col-span-4" : "col-span-4 md:col-span-3"}>
                  {column.text && <Appear>
                    <div className="py-2 md:px-4" style={{ color: textColor() }}>
                      {column.title && <h6 className='mb-3 font-medium md:mb-5 font-secondary text-bigger2'>
                        {column.title}
                      </h6>}
                      <HTMLText text={column.text}/>
                    </div>
                  </Appear>}
                  {column.image && <ImageLazy image={column.image} alt={''} />}
                </div>
              )
            })}
          </div>
        </div>
      </Grid>
    </Container>
  )
}

export default TextMediaColumns
