// @flow

import React, { useRef } from 'react'
import Search from './Search'

type Props = {
  isShowing: boolean,
  setIsShowing: Function,
  language: string,
  searchLabel: string,
  searchPlaceholder: string,
  noResultsMessage: string,
}

const ModalSearch = ({ isShowing, setIsShowing, language, searchLabel, searchPlaceholder, noResultsMessage }: Props) => {
  const modalRef = useRef()
  const closeModal = e =>{
    if (modalRef.current === e.target){
      setIsShowing(false)
    }
  }


  return (
  <div key="search" className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full bg-black bg-opacity-60 z-999" ref={modalRef} onClick={closeModal}>
    <Search language={language} searchLabel={searchLabel} searchPlaceholder={searchPlaceholder} noResultsMessage={noResultsMessage}/>
  </div>
  )
}

export default ModalSearch