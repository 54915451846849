// @flow

import React from 'react'
import Container from './Container'
import ProductCategoryGrid from './ProductCategoryGrid'
import ArrowDownBig from './ArrowDownBig'
import useIsMobile from '../hooks/useIsMobile'

type Props = {
  title: string,
  items: Array<Object>,
}

const HomeProductCategories = ({ title, items }: Props) => {
  const isMobile = useIsMobile()
  const completeItems = []
  const left = []
  const right = []
  let gridIndex = 0

  items.forEach(item => {
    if (item.title && item.image) {
      completeItems.push(item)
    }
  })

  const oddItems = (completeItems.length % 2) !== 0
  
  completeItems.forEach(item => {
      gridIndex += 1
      if ([1, 2, 4].includes(gridIndex)) {
        left.push(item)
      } else {
        right.push(item)
        if (gridIndex === 6) {
          gridIndex = 0
        }
      }
  })

  if (oddItems) {
    right.splice((right.length - 1), 0, left[left.length - 1])
    left.splice((left.length - 1), 1)
  }

  return <Container className="py-20 md:py-24" backgroundColor="bg-white">
    <div className="md:grid md:grid-cols-2 md:gap-6">
      <div className="hidden md:block md:col-start-1 md:col-end-2">
        <div className="row-start-1 row-end-2 my-20">
        <ArrowDownBig/>
        </div>
        <div className="row-start-2">
          <ProductCategoryGrid items={left} display="reverse" />
        </div>
      </div>
      <div className="md:col-start-2 md:col-end-3 md:row-start-1 md:row-span-2">
        <ProductCategoryGrid items={isMobile ? completeItems : right} />
      </div>
    </div>
  </Container>
}

export default HomeProductCategories
