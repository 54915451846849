// @flow

import React from 'react'
import Container from './Container'
import FeaturedCard from './FeaturedCard'
import FeaturedCardBig from './FeaturedCardBig'
import NewsCard from './NewsCard'
import ProductCard from './ProductCard'
import ArrowButton from './ArrowButton'
import Appear from './Appear'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  type: string,
  title: string,
  smallerTitle?: boolean,
  cta: Object,
  items: Array<Object>,
  language: string,
}

const Featured = ({ type, title, smallerTitle, cta, items, language }: Props) => {
  function cardType(item){
    switch(type) {
      case "events":
        return (
          <FeaturedCard
            type={item.type}
            dateFrom={item.dateFrom}
            dateTo={item.dateTo}
            title={item.title}
            place={item.place}
            showLogo={item.showLogo}
            showLink={item.showLink}
            link={item.link}
            url={item.url}
            href={item.href}
          />
        )
      case "news":
        return (
          <NewsCard
            image={item.image}
            title={item.title}
            date={item.date}
            cta={item.cta}
            href={item.href}
          />
        )
      case "products":
        return (
          <ProductCard
            category={item.category}
            categories={item.categories}
            title={item.title}
            href={item.href}
            image={item.image}
            link={item.link}
          />
        )
    }
  }

  if (type === "events") {
    let featuredItem = items.find(item => item.agendaFeatured === '1')

    if (!featuredItem) {
      featuredItem = items[0]
    }

    return (
      <Container
        className="relative py-20 md:pb-32"
        backgroundImage={featuredItem && featuredItem.bgImage !== "" ? `url(${makeAssetUrl({ asset: featuredItem.bgImage, isImage: true })})` : ""}
        backgroundTransparencyColor
      >
      <div className="relative flex items-center justify-between mb-8 md:mb-0">
        <Appear>
          <div 
            className="absolute text-transparent text-stroke font-secondary md:leading-none text-big3 md:text-huge4 lg:text-huge5"
            style={{
              bottom: -550
            }}
          >
            {title}
          </div>
        </Appear>
        <div className="z-10 md:mt-8 md:absolute md:top-0 md:right-0">
          <Appear>
            <ArrowButton
              title={cta.title}
              direction="right"
              color="light"
              featuredSection
              url={cta.href}
            />
          </Appear>
        </div>
      </div>
      <Appear>
        <div
          className="grid grid-cols-1 md:gap-6 md:grid-cols-3"
        >
          {items.map(item => {
            if (item === featuredItem) {
              return (
                <div key={item.id} className="col-span-1 row-start-1 md:col-span-3">
                  <div className="md:w-3/4">
                    <FeaturedCardBig
                    key={Math.random()}
                    type={item.type}
                    dateFrom={item.dateFrom}
                    dateTo={item.dateTo}
                    title={item.title}
                    place={item.place}
                    showLogo={item.showLogo}
                    showLink={item.showLink}
                    link={item.link}
                    url={item.url}
                    href={item.href}
                    bgImage={item.bgImage}
                    language={language}
                    isFeaturedModule={true}
                  />
                </div>
              </div>
              )
            }
            return (
            <div
              key={item.id}
              className={`
                flex mb-5 md:mb-0 md:col-span-1
                ${type === "products" ? "col-span-2" : ""}
              `}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'stretch',
              }}
            >
              {cardType(item)}
            </div>
          )})}
        </div>
      </Appear>
    </Container>
    )
  }

  return (
    <Container className="relative py-20 md:pb-32" backgroundColor="bg-dark">
      <div className="flex items-center justify-between mb-8 md:mb-0">
        <Appear>
          <div 
            className={`
            text-dark text-stroke font-secondary md:leading-none
              ${smallerTitle ? "w-1/2 text-big2 md:text-huge3 leading-tight" : "text-big3 md:text-huge4 lg:text-huge5"}
            `}
          >
            {title}
          </div>
        </Appear>
        <div className="md:mt-32 md:absolute md:top-0 md:right-0">
          <Appear>
            <ArrowButton
              title={cta.title}
              direction="right"
              color="light"
              featuredSection
              url={cta.href}
            />
          </Appear>
        </div>
      </div>
      <Appear>
        <div
          className={`
            md:grid md:gap-6
            ${type === "products" ? "grid gap-4 grid-cols-4 md:-mt-10" : "md:grid-cols-3 md:-mt-20"}
            ${type === "events" ? "md:h-280px" : ""}
          `}
        >
          {items.map(item => (
            <div
              key={item.id}
              className={`
                flex items-stretch mb-5 md:mb-0 md:col-span-1
                ${type === "products" ? "col-span-2" : ""}
              `}
            >
              {cardType(item)}
            </div>
          ))}
        </div>
      </Appear>
    </Container>
  )
}

export default Featured
