// @flow

import React, { useState } from 'react'
import Svg from './Svg'

type Props = {
  justify?: string,
  align?: string,
  direction?: string,
  instagramUrl: string,
  linkedinUrl: string,
}

const SocialNetworks = ({
  justify = "justify-start",
  align = "items-center",
  direction = "flex-row",
  instagramUrl,
  linkedinUrl
}: Props) => {
  
  const[mouseOver1, setMouseOver1] = useState(false)
  const[mouseOver2, setMouseOver2] = useState(false)

  return <div className={`flex ${direction} ${align} ${justify}`}>
  <a
    target="_blank"
    rel="noreferrer"
    onMouseOver={() => setMouseOver1(true)}
    onMouseLeave={() => setMouseOver1(false)}
    className="focus:outline-none"
    href={instagramUrl}
  >
    <Svg
      name="instagram"
      className="mr-2 cursor-pointer"
      color={mouseOver1 ? 'dark' : 'light'}
    />
  </a>
  <a
    target="_blank"
    rel="noreferrer"
    onMouseOver={() => setMouseOver2(true)}
    onMouseLeave={() => setMouseOver2(false)}
    className="focus:outline-none"
    href={linkedinUrl}
  >
    <Svg
      name="linkedin" 
      className="cursor-pointer"
      color={mouseOver2 ? 'dark' : 'light'}
    />
  </a>
  </div>
}

export default SocialNetworks
