// @flow

import React, { useState, useRef, useEffect } from 'react'
import Container from './Container'
import ArrowButtonOpen from 'components/ArrowButtonOpen'
import ArrowButton from 'components/ArrowButton'
import HTMLText from 'components/HTMLText'
import Appear from './Appear'
import useIsMobile from 'hooks/useIsMobile'
import smoothscroll from 'smoothscroll-polyfill'
import makeAssetUrl from '../lib/makeAssetUrl'
import dayjs from 'dayjs'
import { Link } from 'gatsby'

if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}

type Props = {
  items: Array<Object>
}
let scrolltimeout

const drag = {
  active: false,
  start: 0,
  scrollLeft: 0,
  current: 0
}

const Arrows = ({ onClickLeft, onClickRight }) => {
  return (
    <div className="flex">
      <div className="mr-2 md:mr-6">
        <ArrowButton direction="left" color="primary" onClick={onClickLeft} />
      </div>
      <div className="mr-2">
        <ArrowButton direction="right" color="primary" onClick={onClickRight} />
      </div>
    </div>
  )
}

const HomeSlider = ({ items }: Props) => {
  const slider: Ref = useRef(null)
  const infoRef: Ref = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [opacity, setOpacity] = useState(1)
  const isMobile = useIsMobile()

  const parsedDate = items && items[currentIndex] && items[currentIndex].date ? dayjs(items[currentIndex].date).locale("es").format("dddd, DD MMMM, YYYY") : null

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 400)
  }, [currentIndex])

  const handleDrag = e => {
    let pX = e.pageX
    if (e.type.includes("touch") && !e.type.includes("touchend")) {
      pX = e.touches[0].pageX
    }
    let nextIndex = currentIndex
    switch (e.type) {
      case "mousedown":
        // case "touchstart":
        slider.current.classList.remove("snap-x-mandatory")
        drag.active = true
        drag.start = pX - slider.current.offsetLeft
        drag.scrollLeft = slider.current.scrollLeft
        break
      case "mouseup":
        // case "touchend":
        drag.active = false
        if (
          drag.scrollLeft < slider.current.scrollLeft &&
          nextIndex < items.length - 1
        ) {
          nextIndex++
          slideTo(nextIndex)
        }
        if (drag.scrollLeft > slider.current.scrollLeft && nextIndex > 0) {
          nextIndex--
          slideTo(nextIndex)
        }
        break
      case "mousemove":
        // case "touchmove":
        if (drag.active) {
          const x = pX - slider.current.offsetLeft
          // El 2 es arbitrario. Define la velocidad en la que hace el scroll
          const walk = (x - drag.start) * 2
          slider.current.scrollLeft = drag.scrollLeft - walk
        }
        break
      default:
        break
    }
  }

  const slideTo = (index: number): void => {
    setOpacity(0)
    const childW = slider.current.children[0].offsetWidth
    slider.current.scrollTo({
      left: index * childW,
      behavior: "smooth"
    })
    setTimeout(() => {
      setCurrentIndex(index)
    }, 200)
  }

  const handleScroll = ({ target }) => {
    if (isMobile) {
      setTimeout(() => {
        setOpacity(0)
      }, 150)
      if (!target.scrollLeft) {
        setCurrentIndex(0)
        return
      }
      clearTimeout(scrolltimeout)
      scrolltimeout = setTimeout(() => {
        const position =
          target.scrollLeft / (target.offsetWidth * (items.length - 1))
        setTimeout(() => {
          // setCurrentIndex(Math.floor((position / (items.length - 1)) * 10))
          setCurrentIndex(Math.floor(position * (items.length - 1)))
        }, 200)
      }, 500)
    }
  }

  const handleSlider = (direction: string): void => {
    let nextIndex = currentIndex
    const childW = slider.current.children[0].offsetWidth
    switch (direction) {
      case "forwards":
        if (nextIndex < items.length - 1) {
          setOpacity(0)
          nextIndex += 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: "smooth"
          })
          setTimeout(() => {
            setCurrentIndex(nextIndex)
          }, 200)
        } else {
          setOpacity(0)
          slider.current.scrollTo({
            left: 0,
            behavior: "smooth"
          })
          setTimeout(() => {
            setCurrentIndex(0)
          }, 200)
        }
        break
      case "backwards":
        if (nextIndex > 0) {
          setOpacity(0)
          nextIndex -= 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: "smooth"
          })
          setTimeout(() => {
            setCurrentIndex(nextIndex)
          }, 200)
        } else {
          setOpacity(0)
          slider.current.scrollTo({
            left: (items.length - 1) * childW,
            behavior: "smooth"
          })
          setTimeout(() => {
            setCurrentIndex(items.length - 1)
          }, 200)
        }
        break
    }
  }

  let sliderLink = ''
  
  if (items && items[currentIndex]) {
    if (items.[currentIndex].customUrl) {
      sliderLink = items.[currentIndex].customUrl
    } else {
      sliderLink = items[currentIndex].href
    }
  }

  return (
    <Container cleanLeft cleanRight>
      <div className="relative">
        <div
          className="flex items-end w-full overflow-x-auto cursor-pointer md:w-3/4 md:h-50vh md:ml-1/4 h-35vh snap-x-mandatory active:cursor-grab md:overflow-x-hidden"
          ref={slider}
          onMouseDown={handleDrag}
          onMouseMove={handleDrag}
          onMouseUp={handleDrag}
          onScroll={handleScroll}
        >
          {items && items.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundImage: `url(${makeAssetUrl({ asset: item.image, isImage: true })})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover"
              }}
              className="flex-shrink-0 w-full h-full snap-align-start md:snap-align-center"
            ></div>
          ))}
        </div>
        <div className="absolute bottom-0 right-0 hidden mb-6 mr-6 md:block">
          <Arrows
            onClickLeft={() => handleSlider("backwards")}
            onClickRight={() => handleSlider("forwards")}
          />
        </div>
        <div className="w-full p-8 border-l-8 border-solid md:absolute md:bottom-0 md:left-0 md:-mb-6 bg-dark md:w-1/2 md:p-10 border-primary">
          <div
            ref={infoRef}
            className={`transition transition-opacity duration-300 ${
              opacity ? "opacity-1" : "opacity-0"
            }`}
          >
            <Link to={sliderLink} className="cursor-pointer">
              <Appear>
                    {parsedDate !== null && <span className="mb-2 font-light capitalize text-dark-light font-primary text-small2">{parsedDate}</span>}
                    <HTMLText
                      className="mb-4 font-light text-dark-light font-primary text-small1 md:text-normal"
                      text={items && items[currentIndex] && items[currentIndex].supertitle}
                    />
                    <h1 className="mb-4 leading-snug text-dark-light font-secondary text-bigger1 md:text-bigger2">
                      {items && items[currentIndex] && items[currentIndex].title}
                    </h1>
              </Appear>
            </Link>
          </div>
          {/* <div className="absolute bottom-0 right-0 z-10 mr-8 -mb-8">
            <ArrowButtonOpen
              backgroundColor="primary"
              isLink={true}
              url={sliderLink}
            />
          </div> */}
          <div className="md:hidden">
            <Arrows
              onClickLeft={() => handleSlider("backwards")}
              onClickRight={() => handleSlider("forwards")}
            />
          </div>
        </div>
      </div>
    </Container>
  )
}

export default HomeSlider
