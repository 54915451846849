import { createContext, useContext, useEffect, useState } from 'react'

export const BodyScrollContext = createContext([])

export function useInitBodyScrollContext() {
  const [conditions, setConditions] = useState([])

  return { conditions, setConditions }
}

export default function useLockBodyScroll(id, lock): void {
  const { conditions, setConditions } = useContext(BodyScrollContext)

  useEffect(() => {
    if (lock && !conditions.includes(id)) setConditions(current => [...current, id])

    if (!lock && conditions.includes(id)) setConditions(current => {
      current.splice(current.indexOf(id, 1))
      return current
    })

    document.querySelector('body').style.overflow = conditions.length > 0 ? 'hidden' : 'auto'
  }, [lock, conditions])
}