// @flow

import React from 'react'

type Props = {
  children: React$Node,
  image: Object,
  ctas: Array<Object>
}

const AboutWrapper = ({ children }: Props) => {
  return {...children}
}

export default AboutWrapper