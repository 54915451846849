// @flow

import React from 'react'
// import { useLiterals, useMedia } from 'store/'

type Props = {
  color: string,
  background: string,
  title: string
}

const HelloWorld = ({ color, background, title }: Props) => {
  // Asi accedes a los literals y a media
  // const literals = useLiterals()
  // const media = useMedia()
  return <div className={`bg-dark-light text-dark h-32`}>{title}</div>
}

export default HelloWorld