// @flow

import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import Appear from './Appear'
import Container from './Container'
import ArrowDownBig from './ArrowDownBig'
import AboutSlider from './AboutSlider'
import ImageLazy from "./ImageLazy"
import HTMLText from "./HTMLText"
import useIsMobile from 'hooks/useIsMobile'

type Props = {
  supertitle?: string,
  categories?: Array<string>,
  title: string,
  date: string,
  text: string,
  image?: Object,
  slider?: Object,
}

const ItemDetail = ({ supertitle, categories, title, date, text, image, slider }: Props) => {
  const isMobile = useIsMobile()

  const hasImage = image && typeof image === 'object' && image.length !== 0
  const hasImageOrSlider = hasImage || slider

  const parsedDate = date ? dayjs(date).locale("es").format("dddd, DD MMMM, YYYY") : null

  if (categories) {
    supertitle = categories[0]
  }

  const handleSupertitle = () => {
    if (dayjs(supertitle).isValid()) {
      const str = dayjs(supertitle).locale("es").format("dddd, DD MMMM, YYYY")
      return str.charAt(0).toUpperCase() + str.slice(1)
    } else if (typeof supertitle === "string" && supertitle !== "") {
      const str = supertitle
      return str.charAt(0).toUpperCase() + str.slice(1)
    } else {
      return undefined
    }
  }

  return <Appear>
    <div className="relative w-full">
    <div className="absolute top-0 right-0 w-1/2 h-full bg-white"/>
    <Container className="grid grid-cols-4 gap-6 font-light bg-white md:grid-cols-12 font-primary">
      {!isMobile && (
        <div className={`relative md:flex flex-col col-span-4 row-start-2 pb-24 md:pb-0 ${hasImageOrSlider ? 'justify-between md:col-span-6' : 'justify-end md:col-span-4'} ${hasImage ? "md:pt-20" : ""} md:row-start-1`}>
       {hasImageOrSlider && <>
          {hasImage ? (
            <div className="z-10 border border-dark-light">
              
                <ImageLazy image={image} alt={title} />
              
            </div>
          ) : (
            <div className="z-10 -mb-8 md:mb-0">
              {slider && slider.length > 0 && <AboutSlider items={slider} type="itemDetails"/>}
            </div>
          )}
          </>}
          <div className="z-10 mt-40 mb-32">
            <ArrowDownBig />
          </div>
          <div className={`absolute top-0 left-0 ${hasImageOrSlider ? 'w-1/2' : 'w-3/4'} h-full bg-dark-light`} />
        </div>
      )}
      <div className={`z-10 col-span-4 row-start-1 pt-6 pl-6 ${hasImageOrSlider ? 'md:col-span-5' : 'md:col-span-7'} md:col-end-13 md:pl-0 md:pt-20`}>
        {!isMobile && parsedDate && <div className="pb-4 pl-4 font-light capitalize font-primary text-small2">{parsedDate}</div>}
        <HTMLText className="text-small1 md:text-normal" text={`— ${handleSupertitle()}`} />
        <h1 
          className={`
            mt-6 leading-tight mb-8 md:mb-56px md:text-big3 font-secondary md:mt-12 text-primary
            ${hasImage ? "text-bigger4" : "text-big2"}
          `}
        >
          {title}
        </h1>
        <HTMLText
          className="pb-24 md:mb-24 font-primary md:text-normal text-small1 md:pb-20"
          text={text}
        />
        {isMobile && parsedDate && <div className="pb-4 font-light text-right capitalize font-primary text-small2 md:text-small1">{parsedDate}</div>}
      </div>
    </Container>
    {isMobile && hasImageOrSlider &&
      <Container cleanRight>
        <div className="relative flex flex-col justify-between col-span-4 row-start-2 pb-24 bg-white">
          {hasImage ? (
            <div className="z-10 border border-dark-light">
              <ImageLazy image={image} alt={title} />
            </div>
          ) : (
            <div className="z-10 -mb-8">
              {slider && slider.length > 0 && <AboutSlider items={slider} type="itemDetails"/>}
            </div>
          )}
        </div>
      </Container>
    }
  </div>
  </Appear>
}

export default ItemDetail
