// @flow

import React, { useEffect, useRef } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import Container from './Container'
import Appear from './Appear'
import HTMLText from './HTMLText'

type Props = {
  title: string,
  text: string,
  id?: string,
  sectionid?: string,
  menuid?: string,
}

const AboutTitleTextArrow = ({title, text, sectionid, menuid}: Props) => {
  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const isMobile = useIsMobile()
  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])

  return (
    <div data-menu-link={menuAnchor} ref={threshold}>
      <Container className="pt-10 pb-10">
        <div className="grid grid-cols-4 md:grid-cols-12 gap-18px md:gap-6">
          <div className="col-start-1 col-end-5 md:col-start-4 md:col-end-10">
            <Appear>
              <h2 className={`${text ? 'md:mb-8 mb-4' : ''} leading-tight text-primary text-bigger4 md:text-big3 font-secondary`}>{title}</h2>
            </Appear>
            {text && <Appear>
              <HTMLText className="font-light font-primary text-small1 md:text-normal" text={text} />
            </Appear>}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AboutTitleTextArrow