// @flow

import React, { useState } from 'react'
import Container from './Container'
import FeaturedCard from './FeaturedCard'
import FeaturedCardBig from './FeaturedCardBig'
import CalendarFilters from './CalendarFilters'

type FilterType = {
  text: string,
  items: Array<Object>,
  callback: any,
}

type ItemType = {
  id: string,
  type: string,
  dateFrom: string,
  dateTo: string,
  title: string,
  place: string,
  href?: string,
  link?: string,
  url?: string,
  logoImage?: Object,
  agendaFeatured: string,
  bgImage?: Object,
}

type Props = {
  calendarFilters: {
    text: string,
    items: Array<FilterType>
  },
  items: Array<ItemType>,
  logoImage: Object,
  language: string,
}

const CalendarEvents = ({ calendarFilters, items, logoImage, language }: Props) => {
  const parsedItems = items.filter(item => item.title !== '')

  const [filteredItems, setFilteredItems] = useState(parsedItems)
  const [previousFilter, setPreviousFilter] = useState(null)

  const filterEvents = (type) => {
    if (previousFilter === type) {
      setFilteredItems(parsedItems)
      setPreviousFilter(null)
    } else {
      setPreviousFilter(type)
      let filtered = []
      parsedItems.map(item => {
        if (item.type === type) {
          filtered.push(item)
        }
      })
      setFilteredItems(filtered)
    }
  }

  return <div>
    <CalendarFilters
      text={calendarFilters.text}
      items={calendarFilters.items}
      callback={(type) => filterEvents(type)}
    />
    <Container className="grid grid-cols-4 gap-4 pt-6 pb-24 md:grid-cols-3 md:gap-6 md:pt-5 md:pb-32">
        {filteredItems.map((item, index) => {
        const hasFeaturedItem = typeof filteredItems.find(item => item.agendaFeatured === "1") !== 'undefined'
        const firstFeaturedItem = filteredItems.find(item => item.agendaFeatured === '1')
        
        if ((hasFeaturedItem && item === firstFeaturedItem) || (!hasFeaturedItem && index === 0)) {
          return (
            <div
            key={item.id}
            className="col-span-4 row-start-1 mb-1 md:mb-0 md:col-span-3">
              <FeaturedCardBig
                key={Math.random()}
                type={item.type}
                dateFrom={item.dateFrom}
                dateTo={item.dateTo}
                title={item.title}
                place={item.place}
                link={item.link}
                url={item.url}
                href={item.href}
                logoImage={logoImage}
                bgImage={item.bgImage}
                language={language}
              />
            </div>
          )
        } else {
          return (
            <div
            key={item.id}
            className="flex col-span-4 mb-1 md:mb-0 md:col-span-1"
            >
              <FeaturedCard
                key={Math.random()}
                type={item.type}
                dateFrom={item.dateFrom}
                dateTo={item.dateTo}
                title={item.title}
                place={item.place}
                link={item.link}
                url={item.url}
                href={item.href}
                logoImage={logoImage}
                bgImage={item.bgImage !== "" ? item.bgImage : null}
                showBackground={item.showBackground}
              />
            </div>

          )
        }
      })}
    </Container>
  </div>
}

export default CalendarEvents
