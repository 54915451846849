// @flow

import React, { useEffect, useRef } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import Container from './Container'
import Appear from './Appear'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  text: string,
  menuid: string,
  image: Object,
  file?: Object,
}

const AboutImageText = ({ text, image, file, menuid }: Props) => {
  const hasFile = typeof file !== "undefined" && file.file && file.file !== ""

  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const isMobile = useIsMobile()
  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])
  
  return <Container className="grid grid-cols-4 gap-4 pb-10 md:grid-cols-12 md:gap-6">
    <div className="col-start-1 col-end-5 md:col-start-4 md:col-end-13 md:mt-8" data-menu-link={menuAnchor} ref={threshold}>
      
        {hasFile ? (
          <video className="w-full h-auto pointer-events-none" loop muted autoPlay playsInline>
            <source src={makeAssetUrl({ asset: file })} type="video/mp4" />
          </video>
        ) : (
          <ImageLazy image={image} alt={text}/>
        )}
      
    </div>
    <div className="col-start-2 col-end-5 md:col-start-4 md:col-span-9">
      <Appear>
        <HTMLText className="font-light text-right md:text-left text-small2 md:text-normal font-primary" text={text} />
      </Appear>
    </div>
  </Container>
}

export default AboutImageText
