// @flow

import React, { useState, useRef, useContext, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Appear from './Appear'
import FiltersContext from '../store/FiltersContext'
import ArrowButtonOpen from './ArrowButtonOpen'
import useIsMobile from 'hooks/useIsMobile'
import Svg from './Svg'

type Props = {
  cta: string,
  subheader: string,
}

const Cross = ({ active }: { active: boolean }) => {
  return (
    <div className="relative w-4 h-4">
      <div className="absolute top-0 left-0 w-4 h-px mt-2 transform bg-black" />
      <div
        className={`
          absolute top-0 left-0 w-4 h-px mt-2 bg-black 
          transform transition-transform duration-250 ease-in-out ${active ? "rotate-0" : "-rotate-90"}
        `}
      />
    </div>
  )
}

const Checkbox = ({ active }: { active: boolean }) => {
  return (
    <div className={`relative mr-5 flex-0 transition-colors duration-250 ease-in-out w-4 h-4 ${active ? "text-primary" : "text-black"}`}>
      <svg width="17" height="17" fill="none" className="absolute top-0 left-0 -mt-px fill-current" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17 0H0v17h17V0zm-1 1H1v15h15V1z"/></svg>
      <svg width="17" height="17" className={`absolute top-0 left-0 -mt-px fill-current transition-opacity duration-250 ease-in-out ${active ? "opacity-100" : "opacity-0"}`} fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M17 0H0v17h17V0zM1 15.293L7.793 8.5 1 1.707v13.586zM15.293 16L8.5 9.207 1.707 16h13.586zm.707-.707V1.707L9.207 8.5 16 15.293zM15.293 1H1.707L8.5 7.793 15.293 1z"/></svg>
    </div>
  )
}

const Filters = ({
  openedDropdown,
  changeDropdownCallback,
  changeFilterCallback,
  filters,
  activeFiltersIDs
}: {
  openedDropdown: string,
  changeDropdownCallback: (string) => void,
  changeFilterCallback: (string) => void,
  filters: Array<Object>,
  activeFiltersIDs: Array<String>,
}) => {
  return (
    filters.map((item, index) => {
      const listEl = useRef(null)
      const { id, name, isACheckbox, items } = item
      const active = openedDropdown === id

      if (!name) {
        return null
      }

      return (
        <li key={id}>
          {isACheckbox ? (
            <button
              type="button"
              aria-checked={active}
              onClick={() => changeDropdownCallback(id)}
              className="flex items-center justify-start w-full py-4 font-light text-left focus:outline-none hover:text-primary"
            >
              <Checkbox active={active} />
              {name}
            </button>
          ) : (
            <>
              <div
                className="flex items-center justify-between py-3 border-t cursor-pointer"
                onClick={() => changeDropdownCallback(id)}
              >
                <span className={`transition-all duration-250 ease-in-out pr-4 flex-1 ${active ? "font-bold" : ""} hover:text-primary`}>
                  {name}
                </span>
                <Cross active={active} />
              </div>
              <motion.ul
                ref={listEl}
                className={`overflow-hidden ${index + 1 === filters.length ? "border-b" : ""}`}
                initial={false}
                animate={active ? "open" : "close"}
                variants={{open: { height: "auto" }, close: { height: 0 } }}
                transition={{ type: "tween", duration: 0.250, easing: [0.4, 0, 0.2, 1] }}
              >
                {items && items.length > 0 && items.map((subitem, index) => {
                  const { id: subId, name: subName } = subitem
                  const subActive = activeFiltersIDs.includes(subId)
                  return (
                    <li key={subId}>
                      <button
                        type="button"
                        aria-checked={subActive}
                        className={`
                          flex items-center justify-start w-full font-light text-left focus:outline-none pr-4 pb-2
                          ${index === 0 ? "pt-0" : "pt-2"}
                          ${index + 1 === items.length ? "pb-4" : ""}
                        `}
                        onClick={() => changeFilterCallback(subId)}
                      >
                          <Checkbox active={subActive} />
                          {subName}
                      </button>
                    </li>
                  )
                })}
              </motion.ul>
            </>
          )}
        </li>
      )
    })
  )
}

const ProductsFilters = ({ cta, subheader }: Props) => {
  const {
    filters,
    openedDropdown,
    activeFiltersIDs,
    changeDropdownCallback,
    changeFilterCallback,
  } = useContext(FiltersContext)
  const isMobile = useIsMobile()
  const wrapperEl = useRef(null)
  const thresholdEl = useRef(null)
  const [openFilters, setOpenFilters] = useState(false)
  const [distanceFromTop, setDistanceFromTop] = useState(0)
  const [intersectionHeight, setIntersectionHeight] = useState(0)
  
  useEffect(() => {
    const body: any = document.body
    openFilters
      ? (body.style.position = "fixed")
      : (body.style.position = "initial")
  }, [openFilters])

  useEffect(() => {
    !isMobile && setOpenFilters(false)
  }, [isMobile])

  useEffect(() => {
    const wrapper: any = wrapperEl.current
    const threshold: any = thresholdEl.current
    const fromTop = wrapper.getBoundingClientRect().top - 100
    distanceFromTop === 0 && setDistanceFromTop(fromTop)
    intersectionHeight === 0 && setIntersectionHeight(fromTop)

    let observer
    if (threshold !== null && typeof window !== "undefined") {
      observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIntersectionHeight(entry.intersectionRect.height)
        } else {
          setIntersectionHeight(0)
        }
      },
      {
        rootMargin: `-100px`,
        threshold: [...Array(100).keys()].map(x => x / 100),
      }
      )
      observer.observe(threshold)
    }

    return () => observer && observer.unobserve(threshold)
  }, [thresholdEl, isMobile])

  return (
    <>
      <div className="font-primary md:hidden">
        <Appear>
          <button
            type="button"
            className="flex items-center justify-between w-full p-4 text-left border border-black focus:outline-none"
            onClick={() => setOpenFilters(true)}
          >
            {subheader}
            <Svg name="more" color="black" />
          </button>
        </Appear>
        <AnimatePresence initial={false}>
          {openFilters && (
            <motion.div
              className="fixed inset-0 z-20 h-screen overflow-hidden bg-white pt-70px"
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              transition={{ type: "tween", duration: 0.250, easing: [0.4, 0, 0.2, 1] }}
            >
              <motion.div
                className="flex flex-col items-stretch justify-start h-full py-4 overflow-y-scroll"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.15 }}
              >
                <div className="flex items-center justify-between p-4 font-bold">
                  {subheader}
                  <button
                    type="button"
                    className="focus:outline-none"
                    onClick={() => setOpenFilters(false)}
                  >
                    <svg style={{ marginRight: "-4px" }} width="25" height="25" xmlns="http://www.w3.org/2000/svg"><g stroke="#1A1A1A" fill="none" fillRule="evenodd" strokeLinecap="square"><path d="M18.324 7.01L7.01 18.324M7.01 7.01l11.314 11.314"/></g></svg>
                  </button>
                </div>
                <ul className="flex-1 px-4 h-screen-sticky">
                  <Filters
                    openedDropdown={openedDropdown}
                    changeDropdownCallback={changeDropdownCallback}
                    changeFilterCallback={changeFilterCallback}
                    filters={filters}
                    activeFiltersIDs={activeFiltersIDs}
                  />
                </ul>
                <div className="flex items-center justify-start mx-4 my-8">
                  <ArrowButtonOpen backgroundColor="primary" text={cta} onClick={() => setOpenFilters(false)}/>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <div
        ref={wrapperEl}
        className="relative hidden col-span-3 row-span-2 font-light leading-snug border-black mb-30 font-primary md:block"
      >
        <div
          ref={thresholdEl}
          className="w-full h-px -mt-px"
          style={{
            height: `${distanceFromTop}px`,
            marginTop: `-${distanceFromTop}px`,
          }}
        />
        <ul
          className={`sticky top-100px inset-x-0 transition-padding ease-in-out duration-250 overflow-y-auto ${intersectionHeight <= 24 && distanceFromTop > 0 ? "pt-6 lg:pt-0" : "pt-0"}`}
          style={{ height: `calc(100vh - ${100 + intersectionHeight}px)` }}
        >
          <Filters
            openedDropdown={openedDropdown}
            filters={filters}
            changeDropdownCallback={changeDropdownCallback}
            changeFilterCallback={changeFilterCallback}
            activeFiltersIDs={activeFiltersIDs}
          />
        </ul>
      </div>
    </>
  )
}

export default ProductsFilters
