// @flow

import React, { useState, useEffect } from 'react'
import { BodyScrollContext, useInitBodyScrollContext } from "../hooks/useLockBodyScroll"

type Props = {
  children: React$Node,
  route: string,
  header: any
}

const Layout = ({ children }: Props) => {
  const [whiteBackground] = useState(() => (
    children.props.modules.some(node => node.name.includes("ProductDetail")))
  )

  const scrollContextInitialValue = useInitBodyScrollContext()

  return (
    <main>
      <div className="z-50 md:top-0 md:bottom-0 md:h-screen bg-primary right-2 md:block md:fixed md:w-20px" />
      <section
        className={`w-full pt-16 mx-auto md:pl-5 ${whiteBackground ? "bg-white" : "bg-dark-light"}`}
      >
        <BodyScrollContext.Provider value={scrollContextInitialValue}>
          {children}
        </BodyScrollContext.Provider>
      </section>
    </main>
  )
}

export default Layout
