// @flow

import React, { useEffect, useRef, useState } from 'react'
import useEvent from 'hooks/useEvent'
import type { Ref } from 'types/'
import useIsMobile from 'hooks/useIsMobile'
import Container from './Container'
import AboutHeadingLink from './AboutHeadingLink'
import ArrowDownBig from './ArrowDownBig'
import Appear from './Appear'
import ImageLazy from './ImageLazy'
import makeAssetUrl from '../lib/makeAssetUrl'
import config from '../../config'

type Props = {
  image: Object,
  file?: Object,
  titleSection1: string,
  titleSection2: string,
  titleSection3: string,
  urlSection1: string,
  urlSection2: string,
  urlSection3: string,
  menuItems: Array,
}

const AboutHeading = ({
  image,
  file,
  titleSection1,
  titleSection2,
  titleSection3,
  urlSection1,
  urlSection2,
  urlSection3,
  menuItems,
}: Props) => {
  const hasFile = typeof file !== "undefined" && file.file && file.file !== ""
  const isMobile = useIsMobile()
  const observer = useRef(null)
  const arrow: Ref = useRef({})
  const threshold: Ref = useRef({})
  const stickyWrap: Ref = useRef({})
  const stickyEl: Ref = useRef({})
  
  const [height, setHeight] = useState("auto")
  const [arrowHeight, setArrowHeight] = useState(0)
  const [width, setWidth] = useState("auto")
  const [sticky, setSticky] = useState(false)
  const [visible, setVisible] = useState(true)
  const [currentSection, setCurrentSection] = useState(undefined)

  const shouldScrollToId = menuId => {
    const element = document.querySelector(`[data-menu-link="${menuId}"`)
    const yOffset = -100
    if (element) {
      const { top } = element.getBoundingClientRect()
      const y = top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: "smooth" })

      // Arrow anim
      if (isMobile === false) {
        new Promise(resolve => {
          arrow.current.style.transform = "translateY(-24px)"
          setTimeout(() => {
            arrow.current.style.transform = "translateY(0px)"
          }, 325)
          resolve(true)
        })
      }
    }
  }

  useEvent("section", handleSectionIndicator)
  function handleSectionIndicator(event){
    setCurrentSection(event.detail)
  }

  useEvent("resize", udpateDimensions)
  function udpateDimensions() {
    if (isMobile === false) {
      stickyWrap.current !== null && setWidth(`${stickyWrap.current.offsetWidth}px`)
      arrow.current !== null && setArrowHeight(arrow.current.offsetHeight)
    }
  }

  useEvent('hideAboutSticky', handleStickyVisibility)
  function handleStickyVisibility(event) {
    if (isMobile === false) {
      setVisible(!event.detail)
    } else setVisible(true)
  }
  
  useEffect(() => { 
    if (isMobile === false) {
      setTimeout(() => {
        stickyWrap.current !== null && setWidth(`${stickyWrap.current.offsetWidth}px`)
        arrow.current !== null && setArrowHeight(arrow.current.offsetHeight)
      }, 200)
    } else {
      setWidth("100%")
      setVisible(true)
    }

    if (window.IntersectionObserver) {
      let rootMargin
      isMobile === true ? rootMargin = "-71px 0px 0px 0px" : rootMargin = "-148px 0px 0px 0px"

      observer.current = new IntersectionObserver(entries => {
        setSticky(!entries[0].isIntersecting)
      }, {
        root: null,
        rootMargin: rootMargin
      })

      threshold.current && observer.current.observe(threshold.current)

      // On unmount
      return () => {
        observer.current && observer.current.disconnect()
      }
    }
  }, [isMobile, stickyWrap])

  useEffect(() => {
    if (sticky === true && isMobile === true) {
      setHeight(`${stickyEl.current.offsetHeight}px`)
    } else setHeight("auto")
  }, [sticky])

  return (
    <Container padding="px-0 md:px-16">
      <div className="flex flex-col-reverse md:grid md:grid-cols-12 md:gap-6 lg:gap-10">
        <div ref={stickyWrap} className="col-span-4 md:col-span-3" style={{height: height}}>
          <div 
            ref={stickyEl} 
            style={{width: width}}
            className={`md:fixed md:top-0 flex justify-center items-center bg-white md:bg-transparent py-0 md:pb-24 md:pt-40 md:flex-col md:items-stretch md:justify-start md:h-full
            ${visible === true ? "opacity-100" : "opacity-0 pointer-events-none"} transition duration-100 ease-linear
            ${sticky ? "fixed top-70px md:top-0 z-10" : "static"}
            `} 
          >
            {menuItems && menuItems.length > 0 && menuItems.map((item, index) => {
              return <div key={index} className="flex items-center justify-center md:block">
                <AboutHeadingLink
                  first={index === 0} 
                  title={item.title} 
                  url={item.urlSection} 
                  mobile={isMobile}
                  marginBottom={arrowHeight}
                  onClickCallback={shouldScrollToId}
                  currentSection={currentSection}
                />
                {(index+1 !== menuItems.length) ?
                  <Appear>
                    <span className="px-6 md:hidden">|</span>
                  </Appear>
                  : false
                }
              </div>
            })}
            <div ref={arrow} className="items-end self-start justify-start flex-1 hidden transition-transform duration-300 ease-in-out md:flex">
                <ArrowDownBig />
            </div>
          </div>
        </div>
        <div className="flex flex-col-reverse col-span-4 md:flex-col md:col-span-9 md:-mr-16 container:mr-0">
          <div ref={threshold} className="w-full h-px bg-white md:bg-transparent" />
          
            {
              hasFile ? (
                <video className="w-full h-auto pointer-events-none" loop muted autoPlay playsInline>
                  <source src={makeAssetUrl({ asset: file })} type="video/mp4" />
                </video>
              ) : (
                <ImageLazy image={image} alt={titleSection1} className="w-full md:mb-24" />
              )
            }
        </div>
      </div>
    </Container>
  )
}

export default AboutHeading
