// @flow

import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie'
import Container from './Container'
import HTMLText from './HTMLText'
type Props = {
    titleCookies: string,
    text: string,
    ctaLabel: string,
    cookiesPolicyURL: string,
    textCookiesPolicy: string,
    text2CookiesPolicy: string,
    ctaLabelCookiesPolicy: string
}

const ModalCookies = ({
    titleCookies,
    text,
    ctaLabel,
    cookiesPolicyURL,
    textCookiesPolicy,
    text2CookiesPolicy,
    ctaLabelCookiesPolicy
}: Props) => {
    const [isShowingCookiesModal, setIsShowingCookiesModal] = useState(false)

    useEffect(() => {
        if (!Cookies.get("acceptCookie")){
            setIsShowingCookiesModal(true) 
        }
    }, [])

    const acceptCookies = () => {
        const setCookie = Cookies.set('acceptCookie', true)
        if (setCookie) {
            setIsShowingCookiesModal(false)
        }
    }

    if (!isShowingCookiesModal) return null

    return (
        <div key="cookies" className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-full h-full bg-black bg-opacity-70 z-999">
            <Container className="px-8 pt-8 text-white border-l-8 border-primary bg-dark md:px-20 md:pt-18 font-primary md:mx-56">
                <h1 className="mb-4 leading-loose text-center border-b md:mb-8 border-primary">{titleCookies}</h1>
                <div className="h-32 overflow-y-auto text-small2 md:text-small1 md:h-180px">
                    <HTMLText text={text} />
                    <div className="mt-6">
                        {textCookiesPolicy}
                        <a href={cookiesPolicyURL}>
                            <span className={`customLink`}>
                                {ctaLabelCookiesPolicy}
                            </span>
                        </a>
                        {text2CookiesPolicy}
                    </div>
                </div>
                <div className="flex items-center justify-center pb-6 mt-6 md:mt-8 md:pb-8">
                    <button type="button" className="px-2 py-1 border border-white hover:bg-primary hover:border-primary focus:outline-none text-small2 md:text-small1" onClick={acceptCookies}>
                        {ctaLabel}
                    </button>
                </div>
            </Container>
        </div>
    )
}

export default ModalCookies