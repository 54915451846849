// @flow

import React from 'react'
import ProductsCards from './ProductsCards'
import ProductsFiltersTags from './ProductsFiltersTags'

type Props = {
  items: Array<Object>,
}

const ProductsCardsWrap = ({ items }: Props) => {
  return (
    <div className="flex flex-col-reverse items-stretch md:col-span-9">
      <ProductsCards items={items} />
      <ProductsFiltersTags />
    </div>
  )
}

export default ProductsCardsWrap
