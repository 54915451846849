// @flow

import React, { useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { Link } from 'gatsby'
import Appear from './Appear'
import useIsMobile from 'hooks/useIsMobile'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  type: string,
  dateFrom: string,
  dateTo: string,
  title: string,
  place: string,
  href?: string,
  link?: string,
  url?: string,
  bgImage: Object,
  language: string,
  isFeaturedModule?: boolean,
}

const FeaturedCardBig = ({
  type,
  dateFrom,
  dateTo,
  title,
  place,
  href,
  link,
  url,
  bgImage,
  language,
  isFeaturedModule,
}: Props) => {
  const isMobile = useIsMobile()
  let borderColor = ""
  let typeTitle = ""
  switch(type) {
    case "host":
    borderColor = "border-filter-host"
    typeTitle = "Organizado por A2C"
    break
    case "collab":
    borderColor = "border-filter-collab"
    typeTitle = "Colabora A2C"
    break
    case "participate":
    borderColor = "border-filter-part"
    typeTitle= "A2C Participa"
    break
    default:
    borderColor = "border-filter-other"
    typeTitle= null
    break
  }

  const [hover, setHover] = useState(false)

  const parsedDayFrom = dayjs(dateFrom).locale(language).format("DD")
  const parsedDayTo = dayjs(dateTo).locale(language).format("DD")
  const parsedMonthFrom = dayjs(dateFrom).locale(language).format("MMMM")
  const parsedMonthTo = dayjs(dateTo).locale(language).format("MMMM")
  const sameMonth = parsedMonthFrom === parsedMonthTo

  const content = (
    <div className="relative">
      <div className="w-6 h-6 rounded-sm md:w-8 md:h-8 bg-primary" />
      <div className="pl-12">
        <h2 className={`hover:text-primary pb-6 font-semibold leading-none break-normal md:w-full font-secondary text-bigger3 md:text-big5 ${hover ? "text-primary" : "text-white"}`}>{title}</h2>
        <h6 className="font-bold leading-none font-secondary md:text-big2">
          {sameMonth ? `${parsedDayFrom}-${parsedDayTo} ${parsedMonthFrom}` : `${parsedDayFrom} ${parsedMonthFrom} - ${parsedDayTo} ${parsedMonthTo}`}
        </h6>
        <span className="font-normal font-primary text-bigger2">{typeTitle}</span>
      </div>
    </div>
  )

  if (isFeaturedModule) {
    return (
      <Link
        to={href}
      >
        <div className="py-8 pl-5 pr-3 text-white md:px-10 mb:py-12" style={{ minHeight: isMobile ? 300 : 450 }}>
          {content}
        </div>
      </Link>
    )
  }

  return (
    <div className="flex flex-col justify-between flex-1">
    <Appear>
      <Link 
        to={href}
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)} 
      >
      <div
        className={`relative text-white flex flex-col justify-between h-250px md:h-520px py-8 pl-5 pr-3 md:p-12 md:pl-24 md:pt-16 border-l-10 ${borderColor}`}
        style={{
          backgroundImage: `url(${makeAssetUrl({ asset: bgImage, isImage: true })})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}
      > 
        <div
          className="absolute top-0 bottom-0 left-0 right-0"
          style={{
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9))'
          }}
        />
        {content}
      </div>
      </Link>
    </Appear>  
    </div>
  )
}

export default FeaturedCardBig
