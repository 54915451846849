// @flow

import React from 'react'
import Appear from './Appear'
import HTMLText from './HTMLText'
import Container from './Container'
import Grid from './Grid'
import ImageLazy from './ImageLazy'
import VideoFormatter from './VideoFormatter'

type Props = {
  id: string,
  title: string,
  text: string,
  image?: Object,
  youtubeUrl?: string,
  color?: string,
  bgcolor?: string,
}

const AboutTextMedia = ({
  title,
  text,
  image,
  youtubeUrl,
  color,
  bgcolor,
}: Props) => {

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }
  
  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-10">
        <div className="col-span-4 md:col-start-4 md:col-span-4" style={{ color: textColor() }}>
          <Appear>
            <h6
              className={`mb-5 md:mb-6 font-medium font-secondary text-bigger2 ${color ? '' : 'text-primary'}`}
            >
              {title}
            </h6>
            {text && (
              <Appear>
                <HTMLText text={text}/>
              </Appear>
            )}
          </Appear>
        </div>
        <div className="col-span-4 md:col-span-5 md:col-start-8">
          {image && <ImageLazy image={image} alt={title} />}
          {youtubeUrl && <VideoFormatter url={youtubeUrl} />}
        </div>
      </Grid>
    </Container>
  )
}

export default AboutTextMedia
