// @flow

import React from 'react'
import ModuleIterator from 'components/ModuleIterator'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import { GlobalLiterals, GlobalMedia } from 'store/'
import type { Meta, Module } from 'types/'
import ModalCookies from './ModalCookies'
type Props = {
  meta: Meta,
  structure: Array<Module>,
  language: string,
  route: string,
  literals: {},
  media: {}
}

const Page = ({ meta, structure, literals, media, language }: Props) => {
  const {
    title,
    description,
    keywords,
    favicon,
    canonical,
    author
  }: Meta = meta
  return (
    <>
      <Helmet title="es">
        <html lang={language} />
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="language" content={language} />
        <link rel="icon" type="image/gif" href={favicon} />
        <meta
          name="author"
          content={author || 'Fragment Agency, hello@fragment.agency'}
        />
        <meta name="canonical" content={canonical} />
      </Helmet>
      <Layout>
        <ModuleIterator modules={structure} />
      </Layout>
      <ModalCookies
          key="cookies"
          titleCookies={literals.cookies.titleCookies}
          text={literals.cookies.text}
          textCookiesPolicy={literals.cookies.textCookiesPolicy}
          text2CookiesPolicy={literals.cookies.text2CookiesPolicy}
          ctaLabelCookiesPolicy={literals.cookies.ctaLabelCookiesPolicy}
          cookiesPolicyURL={literals.cookies.cookiesPolicyURL}
          ctaLabel={literals.cookies.ctaLabel}
        />
    </>
  )
}

export default Page
