// @flow

import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import type { Ref } from 'types/'
import animations from 'animations/header'
import { navigate } from 'gatsby'
import ImageLazy from './ImageLazy'
import Svg from './Svg'
import Container from './Container'
import { mobileVendor } from 'react-device-detect'
import ModalSearch from './ModalSearch'
import useLockBodyScroll from '../hooks/useLockBodyScroll'

type Navigation = {
  label: string,
  href: string,
  icon?: string,
  id: string,
  active: boolean,
  image?: Object,
  subNavigation: Array<Navigation>
}

type MobileProps = {
  url: string,
  navigation: Array<Navigation>,
  subNavigationCallback?: (href: string) => Promise<any>,
  language: string,
  languages: Array<string>,
}

type DesktopProps = {
  navigation: Array<Navigation>,
  language: string,
  languages: Array<string>,
  logoImage: Object,
  title: String,
  url: string,
  setDesktopSubMenu: Function,
  desktopSubMenu: boolean,
  setIsShowing: Function,
  isShowing: boolean,
}

type Props = {
  logoImage: Object,
  title: String,
  url: string,
  navigation: Array<Navigation>,
  subNavigationCallback?: (href: string) => Promise<any>,
  languages: Array<string>,
  language: string,
  searchLabel: string,
  searchPlaceholder: string,
  noResultsMessage: string,
}


const MobileMenu = ({
  url,
  navigation,
  subNavigationCallback,
  language,
  languages,
}: MobileProps) => {
  const [subNavigationMenu, setSubNavigationMenu] = useState(false)
  const subNavigationRef: Ref = useRef({})
  const handleSubNavigation = async (hasSubNavigation: boolean) => {
    if (hasSubNavigation) {
      if (!subNavigationMenu) {
        setSubNavigationMenu(true)
        await animations.subNavigationIn(subNavigationRef.current)
        return
      }
      setSubNavigationMenu(false)
      await animations.subNavigationOut(subNavigationRef.current)
    }
  }

  return (
    <div className="w-screen h-screen overflow-x-hidden overflow-y-auto text-white bg-dark">
      <div className="relative mx-20 mt-32 mb-32">
        {navigation.map(({ label, icon, subNavigation, href }: Navigation) => (
          <React.Fragment key={label}>
            <div className="flex items-center py-4">
              {icon && (
                <div
                  onClick={() => handleSubNavigation(!!subNavigation)}>
                  <Svg name="arrow-down" color="white" className="mr-4 icon" />
                </div>
              )}
              <div className={icon ? "p-0" : "pl-8"}>
                <Link
                  className="font-thin text-bigger1 font-primary"
                  to={href}
                  onClick={() => {
                    const body: any = document.body
                    body.style.position = "initial"
                  }}
                >
                  {label}
                </Link>
              </div>
            </div>
            {subNavigation && (
              <div
                className={`relative flex flex-col h-0 pl-8 overflow-hidden ${subNavigationMenu ? "mb-20" : "mb-0"}`}
                ref={subNavigationRef}
                style={{
                  transition: "height .3s"
                }}
              >
                {subNavigation.map(({ label: subLabel, href }: Navigation) => (
                  <Link
                    key={subLabel}
                    className="py-2 text-small1 font-primary"
                    to={href}
                    onClick={() => subNavigationCallback && subNavigationCallback(href)}
                  >
                    {subLabel}
                  </Link>
                ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      {/* <div className={`absolute top-70px right-0 flex justify-end ${mobileVendor === "Apple" ? 'mb-18' : ''}`}>
        <div className="flex flex-col text-black">
          {languages.map(lang => (
            <Link
              className={`
              p-6 uppercase w-70px h-70px
              ${language === lang ? "bg-primary text-white" : "bg-dark-light"}
            `}
              key={lang}
              to={url.replace(language, lang)}
            >
              <span
                className={
                  language === lang
                    ? ""
                    : "text-primary border-b border-primary"
                }
              >
                {lang}
              </span>
            </Link>
          ))}
        </div>
      </div> */}
    </div>
  )
}

const Burger = ({ handler, top, bottom }) => (
  <div className="z-10 col-start-2 col-end-3 bg-white h-70px md:hidden">
    <div 
      className="flex items-center justify-center float-right h-full w-70px bg-dark"
      onClick={handler}
    >
      <div className="flex flex-col">
        <span
          style={{
            width: "2rem",
            transition: "transform 1s, margin .3s"
          }}
          className="inline-block bg-white rounded-sm h-2px my-1/2"
          ref={top}
        />
        <span
          style={{
            width: "1.5rem",
            transition: "width .3s, transform 1s, margin .3s"
          }}
          className="self-start inline-block bg-white rounded-sm h-2px my-1/2"
          ref={bottom}
        />
      </div>
    </div>
  </div>
)

const DesktopMenu = ({
  navigation,
  languages,
  language,
  setDesktopSubMenu,
  url,
  isShowing,
  setIsShowing,
}: DesktopProps & any) => {
  const [langSwitchOpen, setLangSwitchOpen] = useState(false)
  const [activeLanguageHover, setActiveLanguageHover] = useState(false)
  const [inactiveLanguageHover, setInactiveLanguageHover] = useState(false)
  const [languagesArray, setLanguagesArray] = useState(languages)
  const langSwitch: Ref = useRef({})

  useEffect(() => {
    let tempLanguagesArray = [...languagesArray]
    let index = tempLanguagesArray.indexOf(language)
    tempLanguagesArray.splice(index, 1)
    tempLanguagesArray.push(language)
    setLanguagesArray(tempLanguagesArray)
  }, [language])

  const handleLangSwitch = async (lang: string) => {
    if (!langSwitchOpen) {
      await animations.languageSwitchOn(langSwitch.current)
      setLangSwitchOpen(true)
      return
    }
    if (lang === language) {
      await animations.languageSwitchOff(langSwitch.current, languages.length)
      setLangSwitchOpen(false)
      return
    }
    navigate(url.replace(language, lang))
  }

  const handleLangHover = async (state: boolean) => {
    if (state === true) {
      await animations.languageSwitchOn(langSwitch.current)
      setLangSwitchOpen(true)
    }
    if (state === false) {
      await animations.languageSwitchOff(langSwitch.current, languages.length)
      setLangSwitchOpen(false)
    }
  }

  const handleHover = (hasSubNavigation: boolean) => {
    setDesktopSubMenu(hasSubNavigation)
  }

  return (
    <>
      <div className="hidden md:h-full md:flex-1 md:justify-end md:items-center md:flex">
        {navigation.map(({ label, href, subNavigation, active }) => (
          <div key={label} className="p-8 font-light">
            <Link
              className={`border-primary hover:border-b hover:text-primary ${
                active ? "border-b" : ""
              }`}
              to={href}
              onMouseEnter={() => handleHover(!!subNavigation)}
            >
              {label}
            </Link>
          </div>
        ))}
        <button className="focus:outline-none md:mx-6" onClick={() => setIsShowing(!isShowing)}>
          <Svg name="search" />
        </button>
        {/* <div
          className="h-full"
          style={{
            transform: `translateY(-${(languages.length * 200) /
              languages.length}px)`,
            transition: "transform .5s, border .5s"
          }}
          ref={langSwitch}
          onMouseEnter={() => {handleLangHover(true)}}
          onMouseLeave={() => {handleLangHover(false)}}
        >
          {languagesArray.map(lang => (
            <div
              onMouseOver={() => lang === language ? setActiveLanguageHover(true) : setInactiveLanguageHover(true)}
              onMouseLeave={() => lang === language ? setActiveLanguageHover(false) : setInactiveLanguageHover(false)}
              key={lang}
              className={`${
                lang === language ? "bg-dark-light hover:bg-primary" : "bg-dark-light hover:bg-primary hover:text-white text-primary"
              } flex items-center justify-center h-full uppercase cursor-pointer w-100px`}
              onClick={() => handleLangSwitch(lang)}
            >
              {lang === language ? (
                <span className={`${activeLanguageHover ? "text-white" : "text-dark"}`}>{lang}</span>
              ) : (
                <Link
                  to={url.replace(language, lang)}
                  className={`border-b ${inactiveLanguageHover ? "" : "text-primary border-primary"}`}
                >
                  {lang}
                </Link>
              )}
            </div>
          ))}
        </div> */}
      </div>
    </>
  )
}

const DesktopSubNavigation = ({
  navigation,
  subNavigationCallback,
  open,
  setDesktopSubMenu,
}: {
  navigation: Array<Navigation>,
  subNavigationCallback: (href: string) => Promise<any>,
  open: boolean,
  setDesktopSubMenu: Function
}) => {
  const productsNavigation: Navigation =
    navigation.find(route => !!route.subNavigation) || navigation[0]
  const { subNavigation }: Navigation = productsNavigation
  const selection: Ref = useRef({})
  const text: Ref = useRef({})
  const [currentSelection, setCurrentSelection] = useState(subNavigation[0])

  useEffect(() => {
    animations.fadeIn(selection.current, text.current)
  }, [currentSelection])

  const handleHover = async (id: string) => {
    const nextSelection: Navigation =
      subNavigation.find(route => route.id === id) || subNavigation[0]
    if (nextSelection.id !== currentSelection.id) {
      await animations.fadeOut(selection.current, text.current)
      setCurrentSelection(nextSelection)
    }
  }

  return (
    <div
      style={{
        transform: open ? "translateY(0)" : "translateY(-120vh)",
        transition: "transform 0.5s"
      }}
      className={`fixed inset-0 z-20 hidden w-full py-24 pl-5 text-white md:block ${ open ? 'mt-100px' : ''} bg-dark max-h-70vh`}
      onMouseLeave={() => setDesktopSubMenu(false)}
    >
      <Container className="grid grid-cols-12">
        <div className="col-span-6 py-8">
          <div className="grid grid-flow-col grid-cols-2 col-gap-8 py-8 grid-rows-7">
            {subNavigation.map(({ label, href, id }) => (
              <div key={id} className="self-start mb-6">
                <Link
                  to={href}
                  className={`font-primary hover:border-b ${
                    id === currentSelection.id ? "border-b" : ""
                  }`}
                  onMouseEnter={() => handleHover(id)}
                  onClick={() => subNavigationCallback(href)}
                >
                  {label}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div
          ref={selection}
          style={{ transition: "opacity .15s" }}
          className="grid grid-cols-6 col-span-6 overflow-hidden grid-rows-7"
        >
          <ImageLazy
            key={currentSelection.id}
            image={currentSelection.image}
            alt={currentSelection.label}
            className="w-full h-full col-span-5 col-start-2 row-span-6 row-start-2"
          />
          <div 
            ref={text} 
            className="z-10 col-span-6 col-start-1 row-span-5 row-start-1 mt-12 leading-none break-words lg:mt-4 stroke-text font-secondary text-big5 lg:text-huge1"
            style={{ transition: "transform opacity .15s ease-out" }}
            >
            {currentSelection.label}
          </div>
        </div>
      </Container>
    </div>
  )
}

const Header = ({
  url,
  logoImage,
  title,
  navigation,
  languages,
  language,
  searchLabel,
  searchPlaceholder,
  noResultsMessage,
}: Props) => {
 
  const [mobileMenu, setMobileMenu] = useState(false)
  const [desktopSubMenu, setDesktopSubMenu] = useState(false)
  const [onTransition, setOnTransition] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const bottom: Ref = useRef({})
  const top: Ref = useRef({})
  const nav: Ref = useRef({})
  const mobile: Ref = useRef({})

  
  useLockBodyScroll('Header', isShowing)

  const handleMobileMenu = async () => {
    setOnTransition(true)
    if (!onTransition) {
      if (!mobileMenu) {
        await animations.burgerIn(top.current, bottom.current, mobile.current)
        setMobileMenu(true)
        setOnTransition(false)
        return
      }
      await animations.burgerOut(top.current, bottom.current, mobile.current)
      setMobileMenu(false)
      setOnTransition(false)
    }
  }

  useEffect(() => {
    return () => {
      const body: any = document.body
      body.style.position = "initial"
    }
  }, [])

  const subNavigationCallback = async (href) => {
    let event = new CustomEvent("productSubcategoryClick", { detail: { href } })
    window.dispatchEvent(event)

    if (mobileMenu) {
      setOnTransition(true)
      await animations.burgerOut(top.current, bottom.current, mobile.current)
      setMobileMenu(false)
      setOnTransition(false)
    }
    if (desktopSubMenu) {
      setDesktopSubMenu(false)
    }
  }

  return (
    <>
      <nav
        className="fixed top-0 right-0 z-50 grid grid-cols-3 bg-white md:grid-cols-12 md:z-50 md:left-20 left-10 md:grid-rows-1 h-70px md:h-100px"
        ref={nav}
      >
        <div className="inset-y-0 z-10 grid items-center col-span-2 bg-white md:col-span-6 md:ml-10 md:-mt-4 md:absolute">
          <Link to={url} className="md:-ml-px">
            <Svg name="logo" className="ml-3 logo md:logo-md md:mt-12 md:-ml-px" />
          </Link>
        </div>
        {/* Mobile */}
        <div className="col-span-1 md:hidden">
          <div className="grid grid-cols-2"> 
            <button className="flex items-center justify-center col-start-1 col-end-2 bg-white focus:outline-none z-999" onClick={() => setIsShowing(!isShowing)}>
              <Svg name="search" />
            </button>
            <Burger bottom={bottom} top={top} handler={handleMobileMenu} />
          </div>
        </div>
       
        <div
          style={{
            transform: "translateY(-100vh)",
            transition: "transform 1s",
            height: "100vh"
          }}
          ref={mobile}
          className="relative col-span-4 md:hidden"
        >
          <MobileMenu
            navigation={navigation}
            subNavigationCallback={subNavigationCallback}
            language={language}
            languages={languages}
            logoImage={logoImage}
            title={title}
            url={url}
          />
        </div>
        {/* Mobile */}
        {/* Desktop */}
        <div className="hidden md:block md:col-start-7 md:col-span-6">
          <div className="h-full mx-auto max-w-container md:pl-16 container:pr-16">
            <DesktopMenu
              navigation={navigation}
              languages={languages}
              language={language}
              logoImage={logoImage}
              title={title}
              url={url}
              setDesktopSubMenu={setDesktopSubMenu}
              desktopSubMenu={desktopSubMenu}
              setIsShowing={setIsShowing}
              isShowing={isShowing}
            />
          </div>
        </div>
        {/* Desktop */}
      </nav>
      <DesktopSubNavigation
        open={desktopSubMenu}
        navigation={navigation}
        subNavigationCallback={subNavigationCallback}
        setDesktopSubMenu={setDesktopSubMenu}
      />
      {isShowing && 
        <ModalSearch 
          key="search"
          isShowing={isShowing} 
          setIsShowing={setIsShowing} 
          language={language} 
          searchLabel={searchLabel} 
          searchPlaceholder={searchPlaceholder} 
          noResultsMessage={noResultsMessage} 
        />}
    </>
  )
}

export default Header
