const checkMenuActive = (props) => {
  const {menuAnchor, isMobile, observer, threshold} = props
  if (menuAnchor !== 'a2caboutmenuid--1') {
    let rootMargin
    let event = new CustomEvent("section", { "detail": menuAnchor})
    isMobile === true ? rootMargin = "-71px 0px 0px 0px" : rootMargin = "-148px 0px 0px 0px"
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        window.dispatchEvent(event)
      }
    }, {
      root: null,
      rootMargin: rootMargin
    })
  
    threshold.current && observer.current.observe(threshold.current)
  
    // On unmount
    return () => {
      observer.current && observer.current.disconnect()
    }
  }
}
  
  export default checkMenuActive