// @flow

import React, { useEffect, useRef, useState } from 'react'
import type { Ref, ImageType } from 'types/'
import Container from './Container'
import SectionTitle from './SectionTitle'
import Appear from './Appear'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'
import SocialNetworks from './SocialNetworks'

const config = require('../../config')
const fetch = require('node-fetch')

type CertificationWithUrl = {
  externalUrl: string | string[]
}

type CertificationWithPdf = {
  pdf: ImageType,
}

type Certification = {
  image: ImageType,
  id: string,
} & (CertificationWithUrl | CertificationWithPdf)

type Props = {
  title: string,
  textLeft: string,
  textRight: string,
  emailusTitle: string,
  emailusURL: string,
  form: Object,
  privacyPolicyTitle: string,
  privacyPolicyURL: string,
  secureDataTitle: string,
  secureDataURL: string,
  cookiesPolicyTitle: string,
  cookiesPolicyURL: string,
  legalQualityTitle: string,
  legalQualityURL: string,
  formSending: string,
  formError: string,
  formSent: string,
  formErrorUserRegistered: string,
  instagram: string,
  linkedin: string,
  newsletterHidden: boolean,
  certifications?: Certification[]
}

const Footer = ({
title,
textLeft,
textRight,
form,
emailusTitle,
emailusURL,
privacyPolicyTitle,
privacyPolicyURL,
secureDataTitle,
secureDataURL,
cookiesPolicyTitle,
cookiesPolicyURL,
legalQualityTitle,
legalQualityURL,
formSending,
formError,
formErrorUserRegistered,
formSent,
instagram,
linkedin,
newsletterHidden,
certifications,
}: Props) => {
  const[mouseOver, setMouseOver] = useState(false)
  const [emailValue, setEmailValue] = useState("")
  const observer = useRef(null)
  const threshold: Ref = useRef({})
  const [loading, setLoading] = useState(false)
  const [apiResponse, setApiResponse] = useState(false)

  console.log(certifications)

  useEffect(() => {
    if (window.IntersectionObserver) {
      observer.current = new IntersectionObserver(entries => {
        let event = new CustomEvent("hideAboutSticky", { "detail": entries[0].isIntersecting})
        window.dispatchEvent(event)
      }, {
        root: null,
        rootMargin: "100%"
      })

      threshold.current && observer.current.observe(threshold.current)

      // On unmount
      return () => {
        observer.current && observer.current.disconnect()
      }
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault()
    setApiResponse(false)
    if (!loading) {
      setLoading(true)
      let formValues = {
        type: 'newsletter',
        email: emailValue,
      }

      fetch(`${config.baseURL}/contact-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        setEmailValue('')
        if (response.body.valid) {
          setApiResponse(formSent)
        } else if (response.body.response === 'User alredy registered') {
          setApiResponse(formErrorUserRegistered)
        } else {
          setApiResponse(formError)
        }
      })
    }
  }

  return <div ref={threshold}>
    <Container className="py-16 md:py-24" backgroundColor="bg-primary">
      <div className="grid grid-cols-4 gap-2 md:grid-cols-12 md:gap-6">
        <div className="col-span-4 row-start-1 row-end-2 mb-8 md:col-start-4 md:col-end-7">
          <SectionTitle title={title} textColor="dark-light" />
        </div>
        <div className="col-span-4 col-start-2 row-start-2 mb-8 md:row-start-1 md:row-end-2 md:col-start-8 md:col-end-10">
          <Appear>
            <SocialNetworks instagramUrl={instagram} linkedinUrl={linkedin} />
          </Appear>
        </div>
        <div className="col-span-3 col-start-2 row-start-3 row-end-3 md:row-start-2 md:col-start-4">
          <Appear>
            <HTMLText className="text-dark-light font-secondary text-normal md:text-bigger3" text={textLeft} />
          </Appear>
          <div className="flex flex-col font-secondary">
            <a className="text-dark-light text-normal md:text-bigger3" href={emailusURL}><Appear><span className="inline-block customLinkFat">{emailusTitle}</span></Appear></a>
          </div>
        </div>
        <div className="col-span-3 col-start-2 row-start-4 row-end-4 md:col-start-8 md:row-start-2 md:row-end-3">
          <Appear>
            <HTMLText className="text-dark-light font-primary text-small1 md:text-normal" text={textRight} />
          </Appear>
        </div>
        {newsletterHidden !== 1 && <div className="col-span-4 row-start-5 row-end-5 md:col-span-7 md:col-start-4 md:row-start-3 md:row-end-4 md:mr-0">
          <Appear>
            <form className="relative flex mt-5 bg-white h-60px" onSubmit={handleSubmit}>
              <input 
                type="email" 
                name="email"
                placeholder={apiResponse ? apiResponse : form.placeholder}
                value={emailValue}
                required={true}
                onChange={(event) => {setEmailValue(event.target.value)}}
                className="flex-1 px-5 py-4 text-dark font-primary text-small1 md:text-normal placeholder-dark" 
              />
              <button 
                type="submit" 
                value="Submit" 
                className="relative pl-4 pr-8 text-primary text-small1 md:text-normal focus:outline-none"
                onMouseOver={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
                disabled={loading}
              >
                <span className={mouseOver ? 'customLinkHover' : 'customLink'}>
                  {!loading ? form.button : formSending}
                </span>
                {/* <div className="absolute bottom-0 right-0 -mb-8 -mr-8">
                  <ArrowButtonOpen backgroundColor="dark" disabled={true} mouseIsOver={mouseOver}/>
                </div> */}
              </button>
            </form>
          </Appear>
        </div>}
        <div className="md:col-start-4 md:col-span-7">
          <Appear>
            <div className="flex flex-row hidden text-white md:block md:text-small1 font-primary">
              <a href={privacyPolicyURL}>
                <span className={`customLink mr-2`}>
                    {privacyPolicyTitle}
                </span>
              </a>
              <a href={secureDataURL}>
                {"|"}
                <span className={`customLink mx-2`}>
                    {secureDataTitle}
                </span>
              </a>
              <a href={cookiesPolicyURL}>
                {"|"}
                <span className={`customLink mx-2`}>
                    {cookiesPolicyTitle}
                </span>
              </a>
              <a href={legalQualityURL}>
                {"|"}
                <span className={`customLink mx-2`}>
                    {legalQualityTitle}
                </span>
              </a>
            </div>
          </Appear>
        </div>
        <div className="flex flex-col block col-span-3 col-start-2 my-5 text-white md:hidden text-small2 font-primary">
          <a href={privacyPolicyURL}>
                <span className={`customLink`}>
                    {privacyPolicyTitle}
                </span>
              </a>
              <a href={secureDataURL}>
                <span className={`customLink`}>
                    {secureDataTitle}
                </span>
              </a>
              <a href={cookiesPolicyURL}>
                <span className={`customLink`}>
                    {cookiesPolicyTitle}
                </span>
              </a>
              <a href={legalQualityURL}>
                <span className={`customLink`}>
                    {legalQualityTitle}
                </span>
              </a>
        </div>
        {certifications && (
          <ul className="flex col-span-3 col-start-2 tex-white md:col-start-4 md:col-span-6">
            {certifications.map(certification => {
              const externalUrl = certification?.externalUrl || []
              const externalUrlString =
                typeof externalUrl === "string" ? externalUrl : externalUrl[0]

              const url = externalUrlString ? externalUrlString : `${config.cloudfront.baseURL}${certification.pdf.name}`

              return (
                <li key={certification.id} className='w-12 mr-3'>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <ImageLazy image={certification.image} alt="" />
                  </a>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </Container>
  </div>
}

export default Footer
