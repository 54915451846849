// @flow

import React, { useEffect, useRef, useState } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import Container from "./Container"
import SectionTitle from "./SectionTitle"
import Appear from "./Appear"
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'
import makeAssetUrl from '../lib/makeAssetUrl'

type ItemType = {
  position: string | Number,
  image: Object,
  name: string,
  id: string,
  file?: Object,
}
type Props = {
  title?: string,
  text: string,
  size: string,
  items: Array<ItemType>,
  menuid?: string,
}

const AboutTeam = ({ title, text, size, items, menuid }: Props) => {

  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const isMobile = useIsMobile()
  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])

  return (
    <Container
      className={`grid grid-cols-4 md:grid-cols-12 gap-4 md:gap-6 ${
        size === "big" ? "md:mb-8" : "md:mb-24"
      }`}
    >
      <div className="col-span-4 md:col-start-4 md:col-span-6" data-menu-link={menuAnchor} ref={threshold}>
        {title ? (
          <div className="py-8">
            <SectionTitle title={title} mobileBiggerText={true} />
          </div>
        ) : (
          ""
        )}
        <Appear>
          <HTMLText
            className="font-light text-normal font-primary"
            text={text}
          />
        </Appear>
      </div>
      <div
        className={`col-span-4 md:col-start-4 md:col-span-9 grid grid-cols-4 md:grid-cols-9 gap-4 md:gap-6 mt-12 ${
          size === "big" ? "mb-12" : "mb-20"
        } flex flex-wrap`}
      >
        {items.map((item, index) => {
          const { file } = item
          const hasFile = typeof file !== "undefined" && file.file && file.file !== ""
          return (
            <div
              key={item.id}
              className={`${size === "big" ? "col-span-4" : "col-span-3"} ${
                size !== "big" && (index +1) % 2 === 0
                  ? "col-start-2 md:col-start-auto"
                  : ""
              }`}
            >
                {hasFile ? (
                  <video className="w-full h-auto pointer-events-none" loop muted autoPlay playsInline>
                    <source src={makeAssetUrl({ asset: file })} type="video/mp4" />
                  </video>
                ) : (
                  <ImageLazy image={item.image} alt={item.name} className="w-full" />
                )}
              <Appear>
                <h6 className="mt-3 mb-6 font-bold md:my-5 font-primary text-normal">
                  {item.name}
                </h6>
              </Appear>
            </div>
          )
        })}
      </div>
    </Container>
  )
}

export default AboutTeam
