// @flow

import React from 'react'
import Appear from './Appear'
import Container from './Container'
import ArrowButtonOpen from './ArrowButtonOpen'

type Props = {
  title: string,
  text: string,
  cta: string,
  href: string
  
}

const Error404 = ({ title, text, cta, href }: Props) => {

  return (
    <Appear>
      <Container>
        <div className="grid grid-cols-4 mb-32 md:grid-cols-12 md:mb-64">
          <h1 className="col-span-2 mb-8 md:col-start-4 md:col-span-6 font-secondary text-bigger4 md:text-big4 md:mb-10" style={{ lineHeight: "105%" }}>{title}</h1>
          <div className="col-span-3 mb-10 font-light md:col-start-4 md:col-span-4 font-primary text-normal md:mb-16">{text}</div>
          <div className="col-span-3 md:col-start-4">
            <ArrowButtonOpen backgroundColor="primary" text={cta} isLink={true} url={href}/>
          </div>
        </div>
        </Container>
    </Appear>
  )
}

export default Error404
