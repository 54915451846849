// @flow

import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import Appear from './Appear'
import Container from './Container'
import Grid from './Grid'
import ProductDetailSectionTitle from './ProductDetailSectionTitle'
import HTMLText from './HTMLText'

type AuthorType = {
  id: string,
  name: string,
}

type ItemType = {
  id: string,
  date: string,
  title: string,
  authors: Array<AuthorType>,
  href: string,
}

type Props = {
  title: string,
  items: Array<ItemType>,
  color?: string,
  bgcolor?: string,
  authorLabel?: string,
  ctaLabel?: string,
}

const ProductDetailPublications = ({ title, items, color, bgcolor, authorLabel, ctaLabel }: Props) => {
  function handleAuthors(authors) {
    if (authors.length > 1) {
        const names = authors.map(author => author.name.split(" ").join("&nbsp;"))
        return `${authorLabel}: ${names.join(", ")}`
    } else {
      return `${authorLabel}: ${authors[0].name}`
    }
  }

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
        <div className="col-span-4 md:col-span-11 md:col-start-2">
          <ProductDetailSectionTitle color={color ? textColor() : null} title={title}/>
          <ul className="flex flex-col items-stretch">
            {items.map((item, index) => {
              const { id, date, title, authors, href } = item
              const parsedDate = date === "" ? undefined : dayjs(date).locale("es").format("MMMM YYYY")
              const last = index + 1 === items.length
              return (
                <li key={id} className="flex flex-col items-stretch justify-start text-small1 text-nomal" style={{ color: textColor() }}>
                  <Appear><div className="w-full h-px bg-gray" /></Appear>
                  <div className="grid grid-cols-4 gap-5 py-5 md:grid-cols-11 md:py-4 md:gap-6">
                    <div className="flex flex-col flex-1 col-span-4 md:col-span-8">
                      {typeof parsedDate !== "undefined" && (
                        <Appear>
                          <span className="block text-small1">{parsedDate}</span>
                        </Appear>
                      )}
                      <Appear>
                        <span className={`mt-3 leading-tight ${authors.length > 0 ? "mb-6" : "mb-3"}`}>
                          {title}
                        </span>
                      </Appear>
                      {authors.length > 0 && (
                        <Appear>
                          <HTMLText className="text-small1" text={handleAuthors(authors)} />
                        </Appear>
                      )}
                    </div>
                    <div className="col-span-4 md:col-span-3">
                      <div className="flex items-center justify-start w-full h-full md:justify-end text-primary">
                        <Appear>
                          <a
                            className="customLink"
                            href={href}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {ctaLabel}
                          </a>
                        </Appear>
                      </div>
                    </div>
                  </div>
                  {last && <Appear><div className="w-full h-px bg-gray" /></Appear>}
                </li>
              )
            })}
          </ul>
        </div>
      </Grid>
    </Container>
  )
}

export default ProductDetailPublications
