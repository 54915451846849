// @flow

import React from 'react'
import Appear from './Appear'
import Container from './Container'

type LinkType = {
  title: string,
  url: string,
  id: string,
}

type Props = {
  indented?: boolean,
  links: LinkType,
}

const Breadcrumbs = ({ indented, links }: Props) => {
  const linksComponent = []
  links.forEach((link, index) => {
    linksComponent.push(
      <div key={index} className="inline-block hover:underline">
        {index !== 0 && <span href="#" className="mx-1 cursor-default">|</span>}
        <a href={link.url}>{link.title}</a>
      </div>
    )
  })

return <Container className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6">
    <div className={`pt-8 pb-6 md:pt-20 md:pb-4 text-gray text-small2 md:text-small1 col-span-4 ${ indented ? "md:col-start-4 md:col-span-9" : "md:col-start-1 md:col-span-12" }`}>
      <Appear>{linksComponent}</Appear>
    </div>
  </Container>
}

export default Breadcrumbs
