// @flow

import React from 'react'
import Appear from './Appear'
import Container from './Container'

type Props = {
  title: string
}

const HeadTitle = ({ title }: Props) => {
  if (typeof title === "string" && title === "") return null
  return (
    <Container className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6">
      <div className="col-span-4 my-6 md:col-span-7">
        <Appear>
          <h1 className="leading-tight font-secondary text-big2 md:text-big4">{title}</h1>
        </Appear>  
      </div>
    </Container>
  )
}

export default HeadTitle
