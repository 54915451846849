// @flow

import React from 'react'
import parseVideo from '../lib/parseVideo'

type Props = {
  url: string,
}

const VideoFormatter = ({ url }: Props) => {
  const { type, id } = parseVideo(url)

  return (
    <div
      className="relative w-full h-0"
      style={{ paddingTop: '56.25%' }}
    >
      {type === 'youtube' && <iframe
          className="absolute inset-0 w-full h-full"
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />}
      {type === 'vimeo' && <iframe
          className="absolute inset-0 w-full h-full"
          src={`https://player.vimeo.com/video/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />}
      {type === 'none' && 
        <video className="absolute top-0 left-0 w-full h-auto focus:outline-none" controls playsInline>
          <source src={url} type="video/mp4" />
        </video>
      }
    </div>       
  )
}

export default VideoFormatter
