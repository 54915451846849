// @flow

import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

type NotFoundContext = {
  languages: Array<string>,
  defaultLanguage: string
}

const Page404 = ({ pageContext }: { pageContext: NotFoundContext }) => {
  const { languages, defaultLanguage } = pageContext
  useEffect(() => {
    const langCode = window.location.pathname.split(`/`)[1]
    if (languages.includes(langCode)) {
      navigate(`/${langCode}/404`)
      return
    }
    navigate(`/${defaultLanguage}/404`)
  }, [])
  return null
}

export default Page404
