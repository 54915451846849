// @flow

import React from 'react'
import HTMLText from './HTMLText'

type Props = {
  active: boolean,
  text: string,
  callback: Function,
}

const FormCheckbox = ({ active, text, callback }: Props) => {

  return <label className="flex items-center">
    <input type="checkbox" className="hidden" checked={active} onChange={(event) => callback(event.target.checked)} />
    <div className={`w-5 h-5 border border-white ${active ? "bg-white" : "" }`}>
    </div>
    <HTMLText className="ml-5 md:ml-8" text={text} />
  </label>
}

export default FormCheckbox
