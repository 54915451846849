// @flow

import React from 'react'
import Appear from './Appear'

type Props = { }

const Dots = ({ }: Props) => {
  return <button>
    <Appear>
    <div className="float-left w-3 h-3 mr-3 border border-black rounded-full" />
    <div className="float-left w-3 h-3 mr-3 border border-black rounded-full" />
    <div className="float-left w-3 h-3 mr-3 border border-black rounded-full" />
    </Appear>
  </button>
}

export default Dots
