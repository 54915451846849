// @flow

import React, { useState } from 'react'
import { Link } from 'gatsby'
import ArrowButtonOpen from './ArrowButtonOpen'
import makeAssetUrl from '../lib/makeAssetUrl'
import dayjs from 'dayjs'
type Props = {
  image: Object,
  title: string,
  date: string,
  cta: string,
  href: string
}
const NewsCard = ({ image, title, date, cta, href }: Props) => {

  const [hover, setHover] = useState(false)

  const treaserTitle = title.length > 100 ? `${title.substring(0, 100)}...` : title
  const parsedDate = date ? dayjs(date).locale("es").format("dddd, DD MMMM, YYYY") : null

  return (
    <Link 
      to={href} 
      className="relative flex flex-col items-stretch flex-1 pb-6 mb-5 bg-white md:mb-0 md:pb-8 group"
      onMouseEnter={() => setHover(true)} 
      onMouseLeave={() => setHover(false)} 
      >
      <div className={`bg-white relative h-250px md:h-250px lg:h-300px w-full overflow-hidden`}>
        <div 
          className="absolute top-0 left-0 w-full h-full transition-transform duration-500 ease-in-out transform bg-top bg-no-repeat bg-cover md:group-hover:scale-110"
          style={{backgroundImage: `url(${makeAssetUrl({ asset: image, isImage: true })})`}}
        />
      </div>
      <div className="flex flex-col justify-between flex-1 px-5 pt-8 align-start md:px-8 md:pt-6">
        {parsedDate && <span className="mb-2 font-normal capitalize font-primary text-small2">{parsedDate}</span>}
        <h3 className="pb-5 font-semibold leading-snug md:pb-10 font-secondary text-normal md:text-bigger3 md:leading-tight">{treaserTitle}</h3>
        <div>
          <span className={`font-light ${hover ? "customLinkHover" : "customLink"} text-small1 md:text-normal text-primary`}>{cta}</span>
        </div>
      </div>
      <div className="absolute bottom-0 right-0 mr-5 -mb-6 md:-mb-35px">
        <ArrowButtonOpen backgroundColor={"primary"} />
      </div>
    </Link>
  )
}

export default NewsCard
