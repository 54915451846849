// @flow

import React, { useState } from 'react'
import { Link } from 'gatsby'
import Svg from './Svg'

type Props = {
  direction: string,
  color?: string,
  width?: string,
  height?: string,
  title?: string,
  onClick?: any,
  disabled?: boolean,
  featuredSection?: boolean,
  url?: string,
}

const ArrowButton = ({
  direction,
  color,
  width,
  height,
  title,
  onClick,
  disabled,
  featuredSection,
  url
}: Props) => {

  const arrowColor = () => {
    switch(color) {
      case "light":
        return "white"
      case "dark":
        return "black"
      case "primary":
        return "primary"
      default:
        return color
    }
  }
  const [mouseOver, setMouseOver] = useState(false)


  const content = <div 
  className="group"
  onMouseOver={() => setMouseOver(true)}
  onMouseLeave={() => setMouseOver(false)}
  >
    {direction === "left" && (
        <div className="flex flex-row items-center outline-none">
          {title && (
            <span
              className={`${
                color === "light" ? `${mouseOver ? 'text-primary' : 'text-dark-light'}` : `${mouseOver ? 'text-primary' : 'text-dark'}`
              }
              ${disabled ? "opacity-25" : "opacity-100"} mx-5 text-small1 md:text-normal`}
            >
              {title}
            </span>
          )}
          <div
            className={`${width ? `w-${width}` : "w-8 md:w-10"} ${
              height ? `h-${height}` : "h-8 md:h-10"
            }`}
            style={{ opacity: disabled ? 0.25 : 1 }}
          >
            <Svg
            name="arrow-left"
            className="transition-transform duration-500 ease-in-out transform md:group-hover:scale-110"
            color={mouseOver ? "primary" : arrowColor()}
            width="100%"
            height="100%"
            />
          </div>
        </div>
      )}
      {direction === "right" && (
        <div className="flex flex-row items-center outline-none">
          {title && (
            <span
              className={`${
                color === "light" ? `${mouseOver ? 'text-primary' : 'text-dark-light'}` : `${mouseOver ? 'text-primary' : 'text-dark'}`
              }
              ${disabled ? "opacity-25" : "opacity-100"} mx-5 text-small1 md:text-normal`}
            >
              {title}
            </span>
          )}
          <div
            className={`${width ? `w-${width}` : "w-8 md:w-10"} ${
              height ? `h-${height}` : "h-8 md:h-10"
            }`}
            style={{ opacity: disabled ? 0.25 : 1 }}
          >
          <Svg
            name="arrow-right"
            className="transition-transform duration-500 ease-in-out transform md:group-hover:scale-110"
            color={mouseOver ? "primary" : arrowColor()}
            width="100%"
            height="100%"
            />
          </div>
        </div>
      )}
  </div>
  if (featuredSection) {
    return <Link
      to={disabled ? "" : url}
    >{content}</Link>
  }

  return (
    <div
    onClick={disabled ? () => {} : onClick}
    className="cursor-pointer"
    >
      {content}
    </div>
  )
}

export default ArrowButton
