// @flow

import React, { useEffect, useState, useRef } from 'react'
import Appear from './Appear'
import throttle from 'lodash/throttle'
import ImageLazy from './ImageLazy'
import config from '../../config'

const fetch = require('node-fetch')
type Props = {
  language: string,
  searchLabel: string,
  searchPlaceholder: string,
  noResultsMessage: string,
}

const handlerSearchInput = throttle((language, value, searchInput) => searchInput(language, value), 2000, {
  leading: false, 
  trailing: true,
})

const Search = ({
  language,
  searchLabel,
  searchPlaceholder,
  noResultsMessage,
}: Props) => {

  const [searchString, setSearchString] = useState('')
  const [searchProducts, setSearchProducts] = useState()

  const inputRef = useRef()

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const searchInput = (language, searchString) => {
    return fetch(`${config.basePublicURL}/search?language=${language}&q=${searchString}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
        },
      })
        .then(response => response.json()
        .then(data => { setSearchProducts(data)})
      )
  }

  const handleChange = (event) => {
    const value = event.target.value
    setSearchString(value)
    if(value.length > 2) {
      handlerSearchInput(language, value, searchInput)
    }
  }

  const handleClick = (e) =>{
    e.preventDefault(), searchInput(language, searchString)
  }
  
  return (
    <>
    <Appear>
      <div>
          <form className="relative flex mb-4 bg-white h-60px">
            <input 
              ref={inputRef}
              type="text" 
              name="text"
              value={searchString}
              placeholder={searchPlaceholder}
              onChange={handleChange}
              className="flex-1 px-5 py-4 focus:outline-none text-dark font-primary text-small1 w-250px md:w-600px md:text-normal placeholder-dark" 
            />
            <button 
              type="submit" 
              value="Submit" 
              className="relative pl-4 pr-8 text-primary text-small1 md: md:text-normal focus:outline-none"
              onClick={handleClick}
            >
             {searchLabel}
            </button>
          </form>
          <div className="overflow-scroll h-200px md:h-300px">
            {searchProducts && <div>
              {searchProducts.length === 0 ? (
                <p className="py-2 text-center text-black bg-white opacity-5 text-small1 md:text-normal">{noResultsMessage}</p>
              ) : <>
                {searchProducts.map((item) => {
                  const parsedImage = JSON.parse(item.image)
                  return (
                    <div key={item.id}>
                      <a href={item.url}>
                        <div className="flex flex-row mb-1 bg-white">
                          <ImageLazy image={parsedImage} alt={item.title} className="w-90px h-60px md:w-110px md:h-80px" />
                          <p className="text-center mt-5 text-small1 ml-4 md:mt-1.75 md:ml-5 text-dark">{item.title}</p>
                        </div>
                      </a>
                    </div>
                  )})}
                </>
              }
            </div>}
          </div>
        </div>
    </Appear>
  </>
 )
}

export default Search