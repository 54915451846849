// @flow

import React, { useEffect, useRef } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import Container from './Container'
import Appear from './Appear'
import ImageLazy from './ImageLazy'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  image: Object,
  file?: Object,
  menuid?: string,
}

const AboutImageFloat = ({ image, file, menuid }: Props) => {
  const hasFile = typeof file !== "undefined" && file.file && file.file !== ""

  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const isMobile = useIsMobile()
  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])

  return <div className="relative mb-8 -mt-24 md:mb-24" data-menu-link={menuAnchor} ref={threshold}>
      <div data-name="dark-section" className="absolute top-0 left-0 bg-dark h-50% w-full" />
      <Container className="grid grid-cols-4 gap-6 md:grid-cols-12" cleanRight cleanLeft>
        <div  className="col-span-4 mx-4 md:mx-0 md:col-start-5 md:col-span-8">
          
            {hasFile ? (
              <video className="w-full h-auto pointer-events-none" loop muted autoPlay playsInline>
                <source src={makeAssetUrl({ asset: file })} type="video/mp4" />
              </video>
            ) : (
              <ImageLazy image={image} alt="" className="w-full" />
            )}
          
        </div>
      </Container>
  </div>
}

export default AboutImageFloat
