// @flow

import React from 'react'
import Appear from './Appear'

type Props = {
  title: string,
  clasName?: string,
  color?: string,
}

const ProductDetailSectionTitle = ({ title, clasName = "", color }: Props) => (
  <Appear>
    <h5 className={`${ color ? '' : 'text-primary'} mb-8 md:mb-10 font-medium leading-tight font-secondary text-bigger3 ${clasName}`} style={{ color: color }}>
      {title}
    </h5>
  </Appear>
)

export default ProductDetailSectionTitle
