// @flow

import React from 'react'

type Props = {
  children: React$Node,
  className?: string,
}


const Grid = ({ children, className = "" }: Props) => {
  return (
    <div className={`font-primary font-light grid grid-cols-4 gap-5 md:grid-cols-12 md:gap-6 ${className}`}>
      {children}
    </div>
  )
}

export default Grid
