// @flow

import React, { useContext, useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import FiltersContext from '../store/FiltersContext'

const ProductsFiltersTags = () => {
  const {
    openedDropdown,
    activeFiltersIDs,
    filters,
    changeDropdownCallback,
    changeFilterCallback,
  } = useContext(FiltersContext)
  const [items, setItems] = useState(() => (
    filters.find(dropdown => dropdown.id === openedDropdown
  )))

  useEffect(() => {
    if (openedDropdown !== "") {
      const activeDropdown = filters.find(dropdown => dropdown.id === openedDropdown)
      if (typeof activeDropdown.items === "undefined" || activeFiltersIDs.length < 1) {
        setItems([{
          id: activeDropdown.id,
          name: activeDropdown.name,
        }])
      } else {
        setItems(activeDropdown.items.filter(item => activeFiltersIDs.includes(item.id)))
      }
    } else {
      setItems(undefined)
    }
  }, [openedDropdown, activeFiltersIDs])

  const handleTagClick = ( id ) => {
    if (activeFiltersIDs.length === 0) {
      changeDropdownCallback(id)
    } else {
      changeFilterCallback(id)
    }
  }

  const Tag = ({ id, name }) => {
    return (
      <li className="mb-2 mr-2">
        <button
          type="button"
          className="flex items-center justify-start p-1 pr-2 transition-colors bg-white md:pr-3 focus:outline-none md:p-2 hover:bg-dark hover:text-white duration-250"
          onClick={() => handleTagClick(id)}
        >
          <div>
            <svg width="25" height="25" className="mr-1 stroke-current" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" strokeLinecap="square"><path d="M18.324 7.01L7.01 18.324M7.01 7.01l11.314 11.314"/></g></svg>
          </div>
          {name}
        </button>
      </li>  
    )
  }

  return (
    <AnimatePresence initial={false}>
      {typeof items !== "undefined" && (
        <motion.div
          className="row-start-2 overflow-hidden leading-none md:row-start-1 md:col-start-4 md:col-end-13 font-primary text-small2 md:text-small1"
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ type: "tween", duration: 0.250, easing: [0.4, 0, 0.2, 1] }}
        >
          <ul className="flex flex-wrap items-center pt-3 md:pb-8 md:pt-0">
            {items.map(item => <Tag key={item.id} id={item.id} name={item.name} />)}
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ProductsFiltersTags