// @flow

import React from 'react'
import Page from 'components/Page'
import type { Module, Meta } from 'types/'
import 'intersection-observer'

type DefaultTemplateContext = {
  meta: Meta,
  structure: Array<Module>,
  language: string,
  route: string,
  literals: {},
  media: {},
  header: {}
}

const DefaultTemplate = ({
  pageContext
}: {
  pageContext: DefaultTemplateContext
}) => {
  const {
    structure,
    meta,
    literals,
    language,
    media,
    route,
    header
  }: DefaultTemplateContext = pageContext
  return (
    <Page
      structure={structure}
      meta={meta}
      literals={literals}
      language={language}
      media={media}
      route={route}
      header={header}
    />
  )
}

export default DefaultTemplate