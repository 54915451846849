
// @flow

import React from 'react'
import Container from './Container'
import NewsCard from './NewsCard'
import Dots from './Dots'

type Props = {
  items: Array<Object>
}

const NewsCards = ({ items }: Props) => {
  return <Container className="grid grid-cols-4 gap-4 pt-16 pb-16 md:pt-32 md:pb-24 md:grid-cols-12 md:gap-6">
    {items.map(item => <div key={item.id} className="col-span-4 mb-2 md:flex md:items-stretch md:mb-8">
    <NewsCard
          image={item.image}
          date={item.date}
          title={item.title}
          cta={item.cta}
          href={item.href}
        />
    </div>
  )}
  {/* <div className="col-span-4 col-start-1 mt-12 md:mt-16">
    <Dots />
  </div> */}
  </Container>
}

export default NewsCards
