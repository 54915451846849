// ¡IMPORTANTE! baseURL y ningun endpoint debe terminar con '/'

const config = {
  // baseURL: 'http://localhost:8080/api',
  baseURL: 'https://manager.a2csum.com/api/public/api',
  basePublicURL: 'https://manager.a2csum.com/api/public/public',
  configEndpoint: '/config',
  cloudfront: {
    baseURL: 'https://a2cweb.s3.eu-west-3.amazonaws.com/',
    resized: '_resized',
  },
}

module.exports = config
