// @flow

import React from 'react'
import ProductCategoryCard from './ProductCategoryCard'

type Props = {
  items: Array<Object>,
  display?: string
}

const ProductCategoryGrid = ({ items, display }: Props) => {
  return (
    <div className="grid grid-cols-2 gap-4 md:gap-6">
      {items.map((item, index) => {
          if (display === "reverse") {
            return (
              <div 
                key={item.id} 
                className={`col-span-${(index + 1) % 3 === 0 ? "2" : "1"} row-span-1`}
              >
                <ProductCategoryCard 
                  title={item.title} 
                  href={item.href} 
                  src={item.image} 
                  alt={item.image.alt} 
                  size={(index + 1) % 3 === 0 ? "big" : "small"} 
                />
              </div>
            )
          }
          return (
            <div 
              key={item.id} 
              className={`col-span-${index === 0 || index % 3 === 0 ? "2" : "1"} row-span-1`}
            >
              <ProductCategoryCard 
                title={item.title} 
                href={item.href} 
                src={item.image} 
                alt={item.image.alt} 
                size={index === 0 || index % 3 === 0 ? "big" : "small"}
              />
            </div>
          )
      })}
    </div>
  )
}

export default ProductCategoryGrid
