// @flow

import React, { useEffect, useRef } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import Appear from './Appear'
import ImageLazy from './ImageLazy'

type Props = {
  image: Object,
  menuid: string,
}

const AboutImageFull = ({ image, menuid }: Props) => {
  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const isMobile = useIsMobile()
  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])

  return (
    <div data-menu-link={menuAnchor} ref={threshold}>
      {image &&
        <ImageLazy image={image} alt="" className="w-full h-auto mb-4 md:mb-70px"/>
       }
    </div>
  )
}

export default AboutImageFull
