// @flow

import React, { useRef, useState } from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'

type Props = {
  image: Object,
  caption?: string,
  color?: string,
  bgcolor?: string,
}


const ProductDetailFullImage = ({ image, caption = "", color, bgcolor }: Props) => {
  const imageRef = useRef(null)
  const [isVertical, setIsVertical] = useState()

  const getImageDimensions = () => {
    if (imageRef && imageRef.current) {
      const height = imageRef.current.offsetHeight
      const width = imageRef.current.offsetWidth
      if (height > width) {
        setIsVertical(true)
      } else if (width > height) {
        setIsVertical(false)
      }
    }
  }
  
  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
       <div className={`relative ${isVertical ? 'col-span-2 col-start-2 md:col-start-2' : 'col-span-4 md:col-span-10 md:col-start-2'}`} style={{ color: textColor() }}>
          <div ref={imageRef}>
            <ImageLazy image={image} alt={caption} onLoadCallback={getImageDimensions}/>
          </div>
          {typeof caption === "string" && caption !== "" && <Appear className="mt-5 text-right text-small1 md:text-left"><HTMLText text={caption} /></Appear>}
        </div>
      </Grid>
      </Container>
  )
}

export default ProductDetailFullImage
