// @flow

import React, { useEffect, useRef, useState } from 'react'
import type { Ref, Image as ImageType } from 'types/'
import WebP from '../lib/WebP'
import addObserver from '../lib/addObserver'
import throttle from '../lib/throttle'
import config from '../../config'
import makeAssetUrl from '../lib/makeAssetUrl'
type Props = {
  className?: string,
  image: ImageType,
  alt: string,
  callback?: any,
  zoomIn?: boolean,
  onLoadCallback?: any,
}

const Image = ({ className = '', image, alt, callback = false, zoomIn = false, onLoadCallback = false }: Props) => {
  const mainRef: Ref = useRef(null)
  const [imageSrc, setImageSrc] = useState(false)
  const [showLazy, setShowLazy] = useState(false)
  const [showImage, setShowImage] = useState(false)
  let lastSize = -1

  const wp = new WebP()
  const loadImage = () => {
    let newSize = 0
    wp.isSupported(webpIsSupported => {
      const availableRes = Object.keys(image).filter(i => i !== 'lazy').sort((a, b) => Number(a) - Number(b))
      availableRes.forEach((size, i) => {
        if (mainRef.current.offsetWidth > size) {
          newSize = size
          if (typeof availableRes[i + 1] !== 'undefined') {
            newSize = availableRes[i + 1]
          }
        }
      })
      if (availableRes.length > 0 && newSize > lastSize) {
        if (typeof image[newSize] === 'undefined') {
          newSize = availableRes[0]
        }
        const imageSize = image[newSize]
        const imageSizeExtensions = Object.keys(imageSize).filter(i => i !== 'webp')
        const imageToLoadUrl = webpIsSupported ? `${config.cloudfront.baseURL}${config.cloudfront.resized}/${image[newSize]['webp']}` : `${config.cloudfront.baseURL}${config.cloudfront.resized}/${image[newSize][imageSizeExtensions[0]]}`
        const imageToLoad = new window.Image()
        imageToLoad.onload = () => {
          if (onLoadCallback) {
            onLoadCallback()
          }
          setImageSrc(imageToLoadUrl)
          lastSize = newSize
        }
        imageToLoad.src = imageToLoadUrl
      }
    })
  }

  const throttleResize = throttle(loadImage, 1000)

  useEffect(() => {
    addObserver(mainRef.current, '300%', 0, () => {
      setShowLazy(true)
    })
    addObserver(mainRef.current, '200%', 0, () => {
      loadImage()
    })
    addObserver(mainRef.current, '0%', 0, () => {
      setShowImage(true)
      if (callback) callback()
    })
    window.addEventListener('resize', throttleResize)
    return () => {
      window.removeEventListener('resize', throttleResize)
    }
  }, [])

  return (
    <div
      ref={mainRef}
      className={`${className ? className : ''} overflow-hidden relative transition duration-normal ${imageSrc && showImage ? 'opacity-100' : 'opacity-0'}`}
    >
      {showLazy &&
      <picture>
        {image.webp && (
          <source
            srcSet={makeAssetUrl({ asset: image, isImage: true, lazy: true, extension: 'webp' })}
            type="image/webp"
          />
        )}
        <img
          className={`w-full ${imageSrc && showImage ? 'opacity-0' : ''}`}
          src={makeAssetUrl({ asset: image, isImage: true, lazy: true })}
          alt={alt}
        />
      </picture>}
      {imageSrc !== false && (
        <img
          className={`absolute top-0 left-0 h-full w-full ${zoomIn ? 'transition duration-normal transform hover:scale-105' : ''}`}
          src={imageSrc}
          alt={alt}
        />
      )}
    </div>
  )
}

export default Image
