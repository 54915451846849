// @flow

import React, { useState } from 'react'
import Appear from './Appear'
import Container from './Container'
import ContactText from './ContactText'
import FormCheckbox from './FormCheckbox'
import ArrowButtonOpen from './ArrowButtonOpen'
import HTMLText from './HTMLText'

const config = require('../../config')
const fetch = require('node-fetch')

type Props = {
  title: string,
  textUp: string,
  links: Array<Object>,
  textDown: string,
  label: string,
  inputs: Array<Object>,
  caption: string,
  checkbox: string,
  cta: string,
  email: string,
  emailtitle: string,
  formTermsValidation: string,
  formSending: string,
  formError: string,
  formSent: string,
}

const ContactForm = ({
  title,
  textUp,
  links,
  textDown,
  label,
  inputs,
  caption,
  checkbox,
  cta,
  email,
  emailtitle,
  formTermsValidation,
  formSending,
  formError,
  formSent
}: Props) => {

  const [nameValue, setNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [messageValue, setMessageValue] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAlertTerms, setShowAlertTerms] = useState(false)
  const [apiResponse, setApiResponse] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()
    setApiResponse(false)
    setShowAlertTerms(false)
    if (termsAccepted && !loading) {
      let formValues = {
        type: 'contact',
        name: nameValue,
        email: emailValue,
        message: messageValue,
      }
  
      setLoading(true)
      fetch(`${config.baseURL}/contact-request`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formValues)
      })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.body.valid) {
          setApiResponse(formSent)
        } else {
          setApiResponse(formError)
        }
      })
    } else {
      setShowAlertTerms(true)
    }
  }

  return <Container className="grid grid-cols-4 gap-0 ml-3 md:pb-32 font-primary md:grid-cols-12 md:gap-6 md:ml-0" cleanRight cleanLeft>
    <div className="col-span-4 row-start-2 md:row-auto md:col-span-6 text-small1 md:text-normal">
      <Appear>
        <div className="pt-10 pb-24 pl-6 pr-4 text-white bg-dark md:py-12 md:px-10 ">
          <form className="flex flex-col items-stretch" onSubmit={handleSubmit}>
            <label className="pb-4">{label}</label>
              <input 
              value={nameValue}
              onChange={(event) => setNameValue(event.target.value)}
              type={inputs[0].type}
              required={inputs[0].required} 
              placeholder={inputs[0].required === true ? (inputs[0].placeholder+"*") : (inputs[0].placeholder)}
              className="w-full px-5 py-4 mb-5 placeholder-white bg-transparent border border-white text-small1 md:text-normal" 
              />
              <input 
              value={emailValue}
              onChange={(event) => setEmailValue(event.target.value)}
              type={inputs[1].type}
              required={inputs[1].required} 
              placeholder={inputs[1].required === true ? (inputs[1].placeholder+"*") : (inputs[1].placeholder)}
              className="w-full px-5 py-4 mb-5 placeholder-white bg-transparent border border-white text-small1 md:text-normal" 
              />
              <textarea
              value={messageValue}
              onChange={(event) => setMessageValue(event.target.value)}
              required={inputs[2].required} 
              placeholder={inputs[2].required === true ? (inputs[2].placeholder+"*") : (inputs[2].placeholder)} 
              rows={8}
              className="w-full px-5 py-4 mb-5 placeholder-white bg-transparent border border-white text-small1 md:text-normal" 
              />
              <HTMLText className="mb-5 leading-tight text-tiny md:mb-8" text={caption} />
              <FormCheckbox text={checkbox} active={termsAccepted} callback={(checked) => setTermsAccepted(checked)}  />
              {showAlertTerms && <p className="mt-2 text-primary text-small2">{formTermsValidation}</p>}
              {!loading &&
                <div className="mt-8 md:mt-10">
                  <ArrowButtonOpen backgroundColor="primary" text={cta} isSubmit={true}/>
                </div>
              }
              {loading &&
                <div className="mt-8 md:mt-10">
                  <ArrowButtonOpen backgroundColor="primary" text={formSending} disabled={true}/>
                </div>
              }
              {apiResponse && <p className="mt-2 text-primary text-small2">{apiResponse}</p>}
          </form>
        </div>
      </Appear>
    </div>
    <ContactText title={title} textUp={textUp} links={links} textDown={textDown} email={email} emailtitle={emailtitle}  />
  </Container>
}

export default ContactForm
