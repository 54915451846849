// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import Appear from './Appear'
import VideoFormatter from './VideoFormatter'
import HTMLText from './HTMLText'

type Props = {
  youtube: string,
  caption?: string,
  color?: string,
  bgcolor?: string,
}

const ProductDetailFullVideo = ({ youtube, caption = "", color, bgcolor }: Props) => {

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  return (
    <Container backgroundColor={backgroundColor()}>
      <Grid className="py-12">
        <div className="col-span-4 md:col-span-10 md:col-start-2" style={{ color: textColor() }}>
          <Appear>
            <VideoFormatter url={youtube} />
          </Appear>
          <Appear>
            {typeof caption === "string" && caption !== "" && <HTMLText
            className="mt-5 text-right md:text-left text-small1"
            text={caption}
            />}
          </Appear>
        </div>
      </Grid>
      </Container>
  )
}

export default ProductDetailFullVideo
