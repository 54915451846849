// @flow

let scrollCache = 0

const animations = {
  burgerIn: (
    topEl: HTMLElement,
    bottomEl: HTMLElement,
    menuEl: HTMLElement
  ): Promise<boolean> => {
    const body: any = document.body
    return new Promise(resolve => {
      bottomEl.style.width = '2rem'
      bottomEl.classList.remove('my-1/2')
      topEl.classList.remove('my-1/2')
      bottomEl.style.transform = 'translate(0, -2px)'
      scrollCache = window.pageYOffset
      setTimeout(() => {
        menuEl.style.transform = 'translateY(-70px)'
        bottomEl.style.transform = 'translate(0, -2px) rotate(45deg)'
        topEl.style.transform = 'rotate(-45deg)'
        setTimeout(() => {
          body.style.position = 'fixed'
        }, 1000)
        resolve(true)
      }, 300)
    })
  },
  burgerOut: (
    topEl: HTMLElement,
    bottomEl: HTMLElement,
    menuEl: HTMLElement
  ): Promise<boolean> => {
    const body: any = document.body
    return new Promise(resolve => {
      body.style.position = 'initial'
      bottomEl.style.transform = 'translate(0, -2px) rotate(0deg)'
      topEl.style.transform = 'rotate(0deg)'
      setTimeout(() => {
        window.scrollTo(0, scrollCache)
        menuEl.style.transform = 'translateY(-100vh)'
        bottomEl.style.transform = 'translate(0, 0)'
        bottomEl.classList.add('my-1/2')
        topEl.classList.add('my-1/2')
        bottomEl.style.width = '1.5rem'
        setTimeout(() => {
          body.style.position = 'initial'
        }, 300)
        resolve(true)
      }, 1000)
    })
  },
  subNavigationIn: (subNavigationEl: HTMLElement): Promise<boolean> => {
    const length = subNavigationEl.children.length
    const childrenHeight = subNavigationEl.children[0].getBoundingClientRect()
      .height
    return new Promise(resolve => {
      subNavigationEl.style.height = `${length * childrenHeight + 16}px`
      resolve(true)
    })
  },
  subNavigationOut: (subNavigationEl: HTMLElement): Promise<boolean> => {
    return new Promise(resolve => {
      window.requestAnimationFrame(() => {
        subNavigationEl.style.height = '0px'
        resolve(true)
      })
    })
  },
  languageSwitchOff: (langSwitchEl: HTMLElement, length: number) => {
    window.requestAnimationFrame(() => {
      langSwitchEl.style.transform = `translateY(-${(100 * (length * 2)) / length}px)`
    })
  },
  languageSwitchOn: (langSwitchEl: HTMLElement) => {
    window.requestAnimationFrame(() => {
      langSwitchEl.style.transform = 'translateY(0)'
    })
  },
  fadeIn: (
    img: HTMLElement,
    text: HTMLElement
    ): Promise<boolean> => {
    return new Promise(resolve => {
      window.requestAnimationFrame(() => {
        img.style.opacity = '1'
        setTimeout(() => {
          text.style.opacity = '1'
          text.style.transform = 'translateY(0%)'
          resolve(true)
        }, 300)
      })
    })
  },
  fadeOut: (
    img: HTMLElement,
    text: HTMLElement
    ): Promise<boolean> => {
    return new Promise(resolve => {
      window.requestAnimationFrame(() => {
        img.style.opacity = '0'
        setTimeout(() => {
          text.style.opacity = '0'
          text.style.transform = 'translateY(15%)'
          resolve(true)
        }, 150)
      })
    })
  }
}

export default animations
