// @flow

import React, { useState, useEffect } from 'react'
import Container from './Container'
import { FiltersContextProvider } from '../store/FiltersContext'

type Props = {
  filters: Array<Object>,
  children: React$Node
}

const ProductsWrap = ({ filters, children }: Props) => {
  const [loaded, setLoaded] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [openedDropdown, setOpenedDropdown] = useState("")
  const [activeFiltersIDs, setActiveFiltersIDs] = useState([])

  function handleParams(action: "get" | "set" | "delete", parameter: string, value?: string) {
    const params = new URLSearchParams(window.location.search)
    switch (action) {
      case "get": return params.get(parameter)
      case "set": params.set(parameter, value || "undefined")
        break
      case "delete": params.delete(parameter)
        break
    }

    let pathname = window.location.pathname
    if (pathname.endsWith("/")) pathname = pathname.slice(0, -1)
    const newURL = `${window.location.origin}${pathname}${params.toString() !== "" ? "?" : "/"}${params.toString().split("%2C").join(",")}`
    window.history.replaceState(null, "", newURL)
  }

  function handleSubcategoryClick(event) {
    const id = event.detail.href.split("=")[1]
    if (id !== null) setOpenedDropdown(id)
    setActiveFiltersIDs([])
  }

  useEffect(() => {
    if (typeof window !== "undefined") setLoaded(true)
  }, [])

  useEffect(() => {
    if (loaded) {
      window.addEventListener("productSubcategoryClick", handleSubcategoryClick)

      const category = handleParams("get", "category")
      if (category !== null) {
        setOpenedDropdown(category)
      }

      const subcategory = handleParams("get", "subcategory")
      if (typeof subcategory === "string") {
        const ids = subcategory.split(",")
        setActiveFiltersIDs(ids)
      }
    }

    setInitialized(true)

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("productSubcategoryClick", handleSubcategoryClick)
      }
    }
  }, [loaded])

  useEffect(() => {
    if (initialized) {
      if (activeFiltersIDs.length > 0) {
        handleParams("set", "subcategory", activeFiltersIDs.toString())
      } else {
        handleParams("delete", "subcategory")
      }
    }
  }, [initialized, activeFiltersIDs])

  function changeDropdownCallback(id) {
    window.scrollTo({ top: 0 })
    setActiveFiltersIDs([])
    if (id !== openedDropdown) {
      handleParams("set", "category", id)
      setOpenedDropdown(id)
    } else {
      handleParams("delete", "category")
      setOpenedDropdown("")
    }
  }

  function changeFilterCallback(id) {
    window.scrollTo({ top: 0 })
    const arr = [...activeFiltersIDs]
    if (arr.includes(id)) {
      const index = arr.indexOf(id)
      arr.splice(index, 1)
      setActiveFiltersIDs(arr)
    } else {
      arr.push(id)
      setActiveFiltersIDs(arr)
    }
  }

  const contextValue = {
    filters,
    openedDropdown,
    changeDropdownCallback,
    activeFiltersIDs,
    changeFilterCallback,
  }

  return (
    <Container
      cleanLeft
      cleanRight
      className="grid mx-2 md:grid-cols-12 md:col-gap-8 md:mx-0"
    >
      <FiltersContextProvider value={contextValue}>
        {children}
      </FiltersContextProvider>
    </Container>
  )
}

export default ProductsWrap
