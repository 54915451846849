// @flow

import React from 'react'

type Props = {
  children: React$Node,
  className?: string,
  backgroundColor?: string,
  padding?: string,
  minHeight?: number | string,
  backgroundImage?: string,
  backgroundTransparencyColor?: boolean,
  overflowHidden?: boolean,
}

const Container = ({
  children,
  className,
  backgroundColor = "transparent",
  padding = "px-4 md:px-16",
  minHeight,
  backgroundImage,
  backgroundTransparencyColor,
  overflowHidden,
}: Props) => {
  const backgroundColorHex = backgroundColor && backgroundColor.includes('#') ? backgroundColor : ''
  const backgroundColorTailwind = backgroundColor && backgroundColor.includes('bg-') ? backgroundColor : ''

  return (
    <div
      className={backgroundColorTailwind}
      style={{
        minHeight: minHeight,
        backgroundColor: backgroundColorHex,
        backgroundImage: backgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        position: "relative",
        overflow: overflowHidden ? "hidden" : "",
      }}>
      {backgroundTransparencyColor && <div
        className="absolute top-0 bottom-0 left-0 right-0"
        style={{
          backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9))"
        }}
      />}
      <div className={`mx-auto max-w-container ${padding}`}>
        <div className={className}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Container
