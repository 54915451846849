// @flow

import React from 'react'
import Appear from './Appear'

type Props = {
  title: string,
  textColor?: string,
  mobileBiggerText?: boolean,
}

const SectionTitle = ({ title, textColor, mobileBiggerText }: Props) => {
  return (
    <Appear>
      <h4 className={`text-${textColor ? textColor : "dark"} font-primary ${mobileBiggerText ? "text-normal" : "text-small1 md:text-normal"} bg-transparent`}>{title}</h4>
    </Appear>
  )
}

export default SectionTitle
