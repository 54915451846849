// @flow

import React, { useEffect, useRef, useState } from 'react'
import useIsMobile from 'hooks/useIsMobile'
import checkMenuActive from '../lib/checkMenuActive'
import ArrowButton from 'components/ArrowButton'
import smoothscroll from 'smoothscroll-polyfill'
import Container from './Container'
import ImageLazy from './ImageLazy'
import HTMLText from './HTMLText'
import makeAssetUrl from '../lib/makeAssetUrl'

if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}

type Props = {
  items: Array<Object>,
  type?: string,
  detailClassName?: string,
  customArrowsColor?: string,
  menuid?: string,
}

const AboutSlider = ({ items, type, detailClassName, customArrowsColor, menuid }: Props) => {
  const slider: Ref = useRef(null)
  const sliderPreview: Ref = useRef(null)
  const infoRef: Ref = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [opacity, setOpacity] = useState(1)
  const isMobile = useIsMobile()
  const [savedItems, setSavedItems] = useState([])

  let menuAnchor = false
  if (menuid) {
    menuAnchor = `a2caboutmenuid-${menuid}`
  }

  const observer = useRef(null)
  const threshold = useRef(null)

  useEffect(() => {
    if (window.IntersectionObserver) {
      checkMenuActive({menuAnchor, isMobile, observer, threshold})
    }
  }, [isMobile])

  const previewItems = () => {
    let newItemsArray = [...items]
    newItemsArray.splice(0, 1)
    newItemsArray.splice(items.length - 1, 1, items[0])
    return newItemsArray
  }

  useEffect(() => {
    if (typeof items !== "undefined") setSavedItems(items)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 400)
  }, [currentIndex])

  const handleSlider = (direction: string): void => {
    let nextIndex = currentIndex
    const childW = slider.current.children[0].offsetWidth
    if (type !== "itemDetails" && !isMobile) {
      const previewChildW = sliderPreview.current.children[0].offsetWidth
      switch (direction) {
        case "forwards":
          if (nextIndex < items.length - 1) {
            setOpacity(0)
            nextIndex += 1
            slider.current.scrollTo({
              left: nextIndex * childW,
              behavior: "smooth"
            })
            sliderPreview.current.scrollTo({
              left: nextIndex * previewChildW,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(nextIndex)
            }, 200)
          } else {
            setOpacity(0)
            sliderPreview.current.scrollTo({
              left: 0,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(0)
            }, 200)
          }
          break
        case "backwards":
          if (nextIndex > 0) {
            setOpacity(0)
            nextIndex -= 1
            slider.current.scrollTo({
              left: nextIndex * childW,
              behavior: "smooth"
            })
            sliderPreview.current.scrollTo({
              left: nextIndex * previewChildW,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(nextIndex)
            }, 200)
          } else {
            setOpacity(0)
            sliderPreview.current.scrollTo({
              left: (items.length - 1) * childW,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(items.length - 1)
            }, 200)
          }
          break
      }
    } else {
      switch (direction) {
        case "forwards":
          if (nextIndex < items.length - 1) {
            setOpacity(0)
            nextIndex += 1
            slider.current.scrollTo({
              left: nextIndex * childW,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(nextIndex)
            }, 200)
          } else {
            setOpacity(0)
            slider.current.scrollTo({
              left: 0,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(0)
            }, 200)
          }
          break
        case "backwards":
          if (nextIndex > 0) {
            setOpacity(0)
            nextIndex -= 1
            slider.current.scrollTo({
              left: nextIndex * childW,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(nextIndex)
            }, 200)
          } else {
            setOpacity(0)
            slider.current.scrollTo({
              left: (items.length - 1) * childW,
              behavior: "smooth"
            })
            setTimeout(() => {
              setCurrentIndex(items.length - 1)
            }, 200)
          }
          break
      }
    }
  }

  if (!items) {
    return null
  }

  if (type === "itemDetails") {
    return (
      <div className={detailClassName || "pb-8 md:pb-32 -mt-100px md:mt-20"}>
        <div className="flex items-center justify-between px-1 py-2 bg-dark-light">
          {items[currentIndex] && items[currentIndex].altImage && <HTMLText
            className={`transition transition-opacity duration-300 ${opacity ? "opacity-1" : "opacity-0"} font-light text-small2 md:text-normal`}
            style={{ color: customArrowsColor }}
            text={items[currentIndex].altImage}
          />}
          <span className="ml-2 font-light text-center min-w-8 font-primary text-small1"
            style={ customArrowsColor && { color: customArrowsColor }}
          >{currentIndex + 1} / {items.length}</span>
        </div>
        <div className="relative w-full">
          <nav
            className="flex items-end w-full overflow-x-hidden overflow-y-hidden bg-white md:h-500px h-235px"
            ref={slider}
          >
            {items.map((item, index) => {
              return (
              <div
                key={index}
                className="flex-shrink-0 w-full h-full border border-dark-light"
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${makeAssetUrl({ asset: item.image, isImage: true })})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "contain"
                }}
              />
            )})}
          </nav>
        </div>
        {items[currentIndex] && items[currentIndex].text && <div className="p-2 bg-dark-light">
          <HTMLText className={`transition transition-opacity duration-300 ${opacity ? "opacity-1" : "opacity-0"} font-light text-small2 md:text-normal`}
            style={{ color: customArrowsColor }}
            text={items[currentIndex].text}
          />
        </div>}
        {savedItems.length > 1 &&
          <div className="flex items-start justify-end w-auto pt-4 md:w-1/2 md:justify-start md:pr-0">
            <div className="flex items-center justify-end md:justify-start">
              <div className="flex items-center justify-center mr-10px md:mr-5">
                <ArrowButton direction="left" onClick={() => handleSlider("backwards")} color={customArrowsColor ? customArrowsColor : 'black'}/>
              </div>
              <ArrowButton direction="right" onClick={() => handleSlider("forwards")} color={customArrowsColor ? customArrowsColor : 'black'}/>
            </div>
          </div>}
      </div>
    )
  }

  return (
    <Container className="pb-8 md:pb-32 -mt-100px flex flex-col items-start justify-end md:pl-25%">
      <div className="relative w-full" data-menu-link={menuAnchor} ref={threshold}>
        <nav
          className="flex items-end w-full overflow-x-auto overflow-x-hidden overflow-y-hidden"
          ref={slider}
        >
          {items.map((item, index) => {
            return (
            <div
              key={index}
              className="flex-shrink-0 w-full h-auto"
            >
              <ImageLazy image={item.image} alt={""} className="w-full h-auto" />
            </div>
          )})}
        </nav>
      </div>
      <div className="flex flex-row w-full">
        {!isMobile
        && <div className="relative w-1/2 mt-6">
          <nav
            className="flex items-end w-full overflow-x-hidden overflow-y-hidden"
            ref={sliderPreview}
          >
            {previewItems().map((item, index) => {
              return (
              <div
                key={index}
                className="flex-shrink-0 w-full h-full"
              >
                <ImageLazy image={item.image} alt={""} className="w-full h-auto"/>
              </div>
            )})}
          </nav>
        </div>}
        {savedItems.length > 1 &&
          <div className="flex items-start justify-end w-full pt-6 md:w-1/2 md:justify-start md:pt-8 md:pl-8">
            {/* <p
              ref={infoRef}
              className={`transition transition-opacity duration-300 ${opacity ? "opacity-1" : "opacity-0"} font-light text-small2 md:text-normal mb-10px md:mb-8`}>{items[currentIndex].text}
            </p> */}
            <div className="flex items-center justify-end md:justify-start">
              <div className="flex items-center justify-center mr-10px md:mr-5">
                <ArrowButton direction="left" onClick={() => handleSlider("backwards")} color={customArrowsColor ? customArrowsColor : 'black'}/>
              </div>
              <span className="mr-2 font-light text-center min-w-8 md:hidden font-primary text-small1"
                style={ customArrowsColor && { color: customArrowsColor }}
              >{currentIndex + 1} - {items.length}</span>
              <ArrowButton direction="right" onClick={() => handleSlider("forwards")} color={customArrowsColor ? customArrowsColor : 'black'}/>
            </div>
          </div>}
      </div>
    </Container>
  )
}

export default AboutSlider
