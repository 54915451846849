// @flow

import React, { useState, useRef, useEffect } from 'react'
import ArrowButton from 'components/ArrowButton'
import Container from 'components/Container'
import Appear from './Appear'
import useIsMobile from 'hooks/useIsMobile'
import smoothscroll from 'smoothscroll-polyfill'
import HTMLText from './HTMLText'
import { Link } from 'gatsby'
import makeAssetUrl from '../lib/makeAssetUrl'

if (typeof document !== 'undefined') {
  smoothscroll.polyfill()
}

type Props = {
  items: Array<Object>
}
let scrolltimeout

const drag = {
  active: false,
  start: 0,
  scrollLeft: 0,
  current: 0
}

const Arrows = ({ onClickLeft, onClickRight }) => {
  return (
    <div className="flex">
      <div className="mr-2 transition duration-500 ease-in-out transform md:mr-6 md:hover:scale-110">
        <ArrowButton direction="left" color="light" onClick={onClickLeft} />
      </div>
      <div className="mr-2 transition duration-500 ease-in-out transform md:hover:scale-110">
        <ArrowButton direction="right" color="light" onClick={onClickRight} />
      </div>
    </div>
  )
}

// const AUTOMATIC_SLIDE_TIME = 10000
// let timeout
let currentIndex = 0

const HomeSlider = ({ items }: Props) => {
  // const [currentIndex, setCurrentIndex] = useState(0)
  const slider: Ref = useRef(null)
  const infoRef: Ref = useRef(null)
  // const [autoPlay, setAutoPlay] = useState(false)
  const [opacity, setOpacity] = useState(1)
  const isMobile = useIsMobile()

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1)
    }, 400)
    // if (autoPlay) {
    //   slideToNext(currentIndex)
    // }
  }, [currentIndex])

  // const slideToNext = (index = currentIndex) => {
  //   timeout = setTimeout(() => {
  //     slideTo(Number(index + 1 < items.length ? index + 1 : 0))
  //   }, AUTOMATIC_SLIDE_TIME)
  // }

  // const stopAutoslideForAWhile = () => {
  //   setAutoPlay(false)
  //   if (timeout) {
  //     clearTimeout(timeout)
  //     timeout = null
  //   }
  //   setTimeout(() => {
  //     startAutoPlay()
  //   }, AUTOMATIC_SLIDE_TIME)
  // }

  // const startAutoPlay = () => {
  //   setAutoPlay(true)
  //   slideToNext()
  // }

  // useEffect(() => {
  //   startAutoPlay()
  // }, [])

  const slideTo = (index: number): void => {

    setOpacity(0)
    if (!slider.current) return null
    const childW = slider.current.children[0].offsetWidth
    slider.current.scrollTo({
      left: index * childW,
      behavior: "smooth"
    })
    setTimeout(() => {
      currentIndex = index
      setOpacity(1)
    }, 200)
  }


  const handleDrag = e => {
    let pX = e.pageX
    if (e.type.includes("touch") && !e.type.includes("touchend")) {
      pX = e.touches[0].pageX
    }
    let nextIndex = currentIndex
    switch (e.type) {
      case "mousedown":
        // case "touchstart":
        slider.current.classList.remove("snap-x-mandatory")
        drag.active = true
        drag.start = pX - slider.current.offsetLeft
        drag.scrollLeft = slider.current.scrollLeft
        break
      case "mouseup":
        // case "touchend":
        drag.active = false
        if (
          drag.scrollLeft < slider.current.scrollLeft &&
          nextIndex < items.length - 1
        ) {
          nextIndex++
          slideTo(nextIndex)
        }
        if (drag.scrollLeft > slider.current.scrollLeft && nextIndex > 0) {
          nextIndex--
          slideTo(nextIndex)
        }
        break
      case "mousemove":
        // case "touchmove":
        if (drag.active) {
          const x = pX - slider.current.offsetLeft
          // El 2 es arbitrario. Define la velocidad en la que hace el scroll
          const walk = (x - drag.start) * 2
          slider.current.scrollLeft = drag.scrollLeft - walk
        }
        break
      default:
        break
    }
  }

  const handleScroll = ({ target }) => {
    if (isMobile) {
      setTimeout(() => {
        setOpacity(0)
      }, 150)
      if (!target.scrollLeft) {
        currentIndex = 0
        setOpacity(1)
        return
      }
      clearTimeout(scrolltimeout)
      scrolltimeout = setTimeout(() => {
        setOpacity(1)
        const position =
          target.scrollLeft / (target.offsetWidth * (items.length - 1))
        setTimeout(() => {
          // setCurrentIndex(Math.floor((position / (items.length - 1)) * 10))
          currentIndex = Math.floor(position * (items.length - 1))
          setOpacity(1)
        }, 200)
      }, 500)
    }
  }

  const handleSlider = (direction: string): void => {

    // stopAutoslideForAWhile()

    let nextIndex = currentIndex
    const childW = slider.current.children[0].offsetWidth
    switch (direction) {
      case "forwards":
        if (nextIndex < items.length - 1) {
          setOpacity(0)
          nextIndex += 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: "smooth"
          })
          setTimeout(() => {
            currentIndex = Number(nextIndex)
            setOpacity(1)
          }, 200)
        } else {
          setOpacity(0)
          slider.current.scrollTo({
            left: 0,
            behavior: "smooth"
          })
          setTimeout(() => {
            currentIndex = Number(0)
            setOpacity(1)
          }, 200)
        }
        break
      case "backwards":
        if (nextIndex > 0) {
          setOpacity(0)
          nextIndex -= 1
          slider.current.scrollTo({
            left: nextIndex * childW,
            behavior: "smooth"
          })
          setTimeout(() => {
            currentIndex = Number(nextIndex)
            setOpacity(1)
          }, 200)
        } else {
          setOpacity(0)
          slider.current.scrollTo({
            left: (items.length - 1) * childW,
            behavior: "smooth"
          })
          setTimeout(() => {
            currentIndex = Number(items.length - 1)
            setOpacity(1)
          }, 200)
        }
        break
    }
  }

  const teaser = items[currentIndex].text.length > 95 ? `${items[currentIndex].text.substring(0, 95)}...` : items[currentIndex].text
  const sliderLink = items[currentIndex].customUrl ? items[currentIndex].customUrl : items[currentIndex].href

  // const playingVideo = (e) => {
  //   e.currentTarget.classList.add('absolute','z-30','left-0','right-0')
    // setAutoPlay(false)
  // }
  
  // const pausingVideo = (e) => {
  //   e.currentTarget.classList.remove('absolute','z-30','left-0','right-0')
  //   e.currentTarget.classList.add('block')
    // setTimeout(() => {
    //   setAutoPlay(true)
    // }, 1000)
  // }
  
 
  return (
    <div className="relative">
      <nav
        className="flex items-end w-full overflow-x-auto cursor-pointer md:h-33vw h-65vw snap-x-mandatory active:cursor-grab md:overflow-x-hidden"
        ref={slider}
        onMouseDown={handleDrag}
        onMouseMove={handleDrag}
        onMouseUp={handleDrag}
        onScroll={handleScroll}
      >
        {items.map((item, index) => {
          if (item.video && item.video.length > 0) {
            return (
            <div key={index} className="flex-shrink-0 w-screen h-full overflow-hidden bg-black snap-align-start md:snap-align-center">
              <div
              className="relative flex items-center justify-center w-full h-full"
              dangerouslySetInnerHTML={{__html: `
                <video height="100%" class='absolute min-w-full min-h-full max-w-initial md:h-auto md:w-full' playsInline autoPlay loop muted>
                  <source src="${item.video}" type="video/mp4"/>
                </video>
              `}}
              >

              </div>
            </div>
          )}
          return (
          <div
            key={index}
            style={{
              backgroundImage: `url(${makeAssetUrl({ asset: item.image, isImage: true })})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover"
            }}
            className="flex-shrink-0 w-screen h-full snap-align-start md:snap-align-center"
          ></div>
        )})}
      </nav>
      <div className="inset-x-0 bottom-0 md:absolute">
        <Container
          padding="md:px-16"
          className="grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6 lg:gap-10"
        >
          <div className="items-end justify-start hidden col-span-2 md:pb-12 md:flex">
            <Arrows
              onClickLeft={() => handleSlider("backwards")}
              onClickRight={() => handleSlider("forwards")}
            />
          </div>
          <div className="relative col-span-4 py-8 pl-8 pr-4 border-l-8 md:py-12 md:pl-12 md:pr-10 md:col-span-7 md:col-end-13 md:-mb-56px bg-dark border-primary">
            <div
              ref={infoRef}
              className={`transition-opacity duration-300 ${opacity ? "opacity-1" : "opacity-0"}`}
            >
              <Link to={sliderLink} className="cursor-pointer">
                <Appear>
                  <HTMLText
                    className="max-w-md mb-4 font-light text-dark-light font-primary text-small2 md:text-small1"
                    text={items[currentIndex].supertitle}
                  />
                  <h1 className="max-w-md mb-4 leading-tight text-dark-light font-secondary text-bigger1 md:text-big1">
                    {items[currentIndex].title}
                  </h1>
                  <HTMLText
                    className="hidden mb-4 font-light md:block text-dark-light text-small1 md:text-normal font-primary"
                    text={isMobile ? teaser : items[currentIndex].text}
                  />
                </Appear>
              </Link>
            </div>
            <div className="md:hidden">
              <Arrows
                onClickLeft={() => handleSlider("backwards")}
                onClickRight={() => handleSlider("forwards")}
              />
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}


export default HomeSlider