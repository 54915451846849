// @flow

import React, { useRef, useEffect } from 'react'
import Appear from './Appear'
import { CountUp } from 'countup.js'

type Props = {
  value: string,
  title: string,
  text: string,
  textColor?: string
}

const Number = ({ value, title, text, textColor }: Props) => {

  const plus = value && value.includes("+") ? "+" : null

  const animatableNumber = useRef(null)
  let countUp = null
  useEffect(() => {
    countUp = new CountUp(animatableNumber.current, parseInt(value), {
      duration: 5,
      separator: '.',
    })
  })

  const startCounting = () => countUp.start()

  return <div className="flex flex-col mb-8 bg-transparent md:pr-6 md:mb-12">
    <div className="font-secondary text-big5 md:text-huge1 text-primary">
      <Appear className="flex" callback={startCounting}>{plus}<div ref={animatableNumber}></div></Appear>
    </div>
    <div className={`font-secondary tesxt-bigger31 md:text-big2 text-${textColor ? textColor : "dark"} pb-4 leading-none`}>
      <Appear>{title}</Appear>
    </div>
    <Appear>
      <p className={`font-light font-primary text-small1 md:text-normal text-${textColor ? textColor : "dark"}`}>
        {text}
      </p>
    </Appear>
  </div>
}

export default Number
