// @flow

import React from 'react'
import Appear from './Appear'
import Container from './Container'
import ArrowDownBig from './ArrowDownBig'
import ImageLazy from './ImageLazy'
import makeAssetUrl from '../lib/makeAssetUrl'

type Props = {
  image: Object,
  pin: Object,
  address: string
}

const ContactMap = ({ image, pin, address }: Props) => {

  return <Container className="relative grid grid-cols-4 gap-4 md:grid-cols-12 md:gap-6" cleanLeft cleanRight>
    <Appear>
      <div className="absolute bottom-0 left-0 hidden mb-24 md:block">
        <ArrowDownBig/>
      </div>
    </Appear>
    <a 
      className="relative col-span-4 md:col-start-4 md:col-span-9 group"
      href="https://goo.gl/maps/S1NQBhdgn4xRig9RA"
      target="_blank"
      rel="noopener noreferrer"
    >
        <img
        className="absolute w-2 md:w-4 h-2 md:h-4 left-46% bottom-48% transition-transform z-10 duration-500 ease-in-out transform md:group-hover:scale-110"
        src={makeAssetUrl({ asset: pin, isImage: true })}
        />
        <div className="z-0 w-full h-full overflow-hidden">
          <ImageLazy
          image={image}
          alt={address}
          className="z-0 w-full h-full transition-transform duration-1000 ease-in-out transform md:group-hover:scale-110"
          />
        </div>
    </a>
  </Container>
}

export default ContactMap

// <img className="z-0 w-full h-full transition-transform duration-1000 ease-in-out transform group-hover:scale-110" src={image.normal} alt={image.alt}/>