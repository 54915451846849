// @flow

import React from 'react'
import Container from './Container'
import Grid from './Grid'
import HTMLText from './HTMLText'
import Appear from './Appear'
import ImageLazy from './ImageLazy'

type Props = {
  image: Object,
  supertitle: string,
  title: string,
  text: string,
  color?: string,
  bgcolor?: string,
}

const ProductDetailHeader = ({ image, supertitle, title, text, color, bgcolor}: Props) => {
  const hasImage = image.extension !== ""

  const textColor = () => {
    if (color) {
      if (color.includes('#')) {
        return color
      } else {
        return `#${color}`
      }
    } else {
      return '#000000'
    }
  }

  const backgroundColor = () => {
    if (bgcolor) {
      if (bgcolor.includes('#')) {
        return bgcolor
      } else {
        return `#${bgcolor}`
      }
    } else {
      return 'transparent'
    }
  }

  return (
    <div className="relative">
      <Container className="relative" backgroundColor={backgroundColor()}>
        <Grid className={`overflow-hidden py-12 ${bgcolor ? '' : 'bg-white'}`} style={{ backgroundColor: backgroundColor() }}>
          <div className="col-span-4 md:col-span-5 md:col-start-2" style={{ color: textColor() }}>
            {supertitle !== "" && (
              <Appear>
                <HTMLText className="mb-8 md:mb-5" text={supertitle} />
              </Appear>
            )}
            <Appear>
              <h1 className={`font-medium leading-none ${ color ? '' : 'text-primary'} font-secondary text-big3 md:text-big5`}>
                {title}
              </h1>
            </Appear>
            {text !== "" && (
              <Appear>
                <HTMLText
                  text={text}
                  className="mt-8 mb-12 md:mb-0 md:mt-10 markdown"
                />
              </Appear>
            )}
          </div>
          {hasImage && (
            <div className="flex items-center justify-center col-span-4 -mr-5 overflow-hidden md:mr-0 md:col-span-6 max-h-400px md:max-h-800px">
              <ImageLazy className="w-full" image={image} alt={title} />
            </div>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default ProductDetailHeader
